import { useMemo } from "react";

import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import { UTILS_LIST } from "constant";
import { AuthStateInterface } from "scenes/AuthPage/ducks";

const useAvailableTools = () => {
  const { jwtData } = useSelector<RootStateInterface, AuthStateInterface>(
    (state) => state.auth
  );

  const availableTools = useMemo(
    () =>
      jwtData?.policies &&
      UTILS_LIST.filter(
        (item) => jwtData?.policies.includes(item.policyId) && item
      ),
    [jwtData]
  );

  return availableTools;
};

export default useAvailableTools;
