import { env } from "./env";

import { MainUtilInterface } from "components/MainUtil/MainUtil";

import AiHelperPicture from "assets/ai-helper-picture.png";
import LabelingPicture from "assets/labeling-picture.png";
import OdmPicture from "assets/odm-picture.png";
import WikiPicture from "assets/wiki-picture.png";
import MineDetectionPicture from "assets/mine-detection-picture.png";

import RockIcon from "assets/icons/rock.svg";
import CrosshairIcon from "assets/icons/crosshair.svg";
import MineIcon from "assets/icons/mine.svg";
import TreeIcon from "assets/icons/tree-pine.svg";
import StumpIcon from "assets/icons/stump.svg";

import CheckCheckIcon from "assets/icons/check-check.svg";
import AlertTriangleIcon from "assets/icons/alert-triangle.svg";
import LoaderOrangeIcon from "assets/icons/loader-orange.svg";
import LoaderGreyIcon from "assets/icons/loader-grey.svg";
import BanRedIcon from "assets/icons/ban-red.svg";

export const AUTH_URL = env.REACT_APP_AUTH_URL;
export const GEO_VISION_API_URL = env.REACT_APP_GEO_VISION_API_URL;
export const ODM_API_URL = env.REACT_APP_ODM_API_URL;
export const EXTERNAL_API_URL = env.REACT_APP_EXTERNAL_API_URL;
export const INFERENCE_API_URL = env.REACT_APP_INFERENCE_API_URL;
export const PRIVATE_KEY = env.REACT_APP_PRIVATE_KEY;
export const STORAGE_URL = env.REACT_APP_STORAGE_URL;

export const UNITS_LIMIT = 10;

//DELETE
export const AREA_COLORS = [
  {
    name: "Medium Hazard",
    color: "rgba(255, 122, 0, 0.8)",
    alias: "orange",
    isActive: false,
    radius: 3
  },
  {
    name: "Hard Hazard",
    color: "rgba(255, 0, 0, 0.8)",
    alias: "red",
    isActive: false,
    radius: 3
  }
];

const ODM_LINK = env.REACT_APP_ODM_LINK;
const CVAT_LINK = env.REACT_APP_CVAT_LINK;
const AI_HELPER_LINK = env.REACT_APP_AI_HELPER_LINK;
export const WIKI_LINK = env.REACT_APP_WIKI_LINK;

export const UTILS_LIST: Array<MainUtilInterface> = [
  {
    policyId: "inference_general",
    title: "ODM",
    icon: OdmPicture,
    link: ODM_LINK,
    target: "_blank"
  },
  {
    policyId: "labeling_general",
    title: "Labeling tool",
    icon: LabelingPicture,
    link: CVAT_LINK,
    target: "_blank"
  },
  {
    policyId: "ai_helper",
    title: "AI Helper",
    icon: AiHelperPicture,
    link: AI_HELPER_LINK,
    target: "_blank"
  },
  {
    policyId: "wiki_general",
    title: "Wiki mine",
    icon: WikiPicture,
    link: WIKI_LINK,
    target: "_blank"
  },
  {
    policyId: "inference_general", //TODO: change policyId when it is available
    title: "Mine detection",
    icon: MineDetectionPicture,
    link: "/image-detection"
  }
  //TODO delete orthophoto-planning scenes
  // { title: "Map", icon: MapIcon, link: "/map" },
  // {
  //   title: "Orthophoto Planning",
  //   icon: CameraIcon,
  //   link: "/orthophoto-planning"
  // }
];

export const KYIV_COORDINATES = { lat: 50.450001, lng: 30.523333 };

export enum MapModesEnum {
  DEFAULT = "default",
  POLYGON_CREATION = "polygon_creation"
}

export enum LayerAlias {
  RGB = "rgb",
  RGB2 = "rgb2",
  MULTISPECTRAL = "multispectral",
  THERMAL = "thermal",
  MAGNETIC = "magnetic",
  SENSOR_FUSION = "sensor-fusion",
  MANUAL = "manual"
}

export interface LayerInterface {
  name: string;
  color?: string;
  alias: LayerAlias;
  isCustom?: boolean;
  isUseFileManager?: boolean;
}

export const LAYERS: Record<LayerAlias, LayerInterface> = {
  [LayerAlias.SENSOR_FUSION]: {
    name: "Sensor fusion",
    color: "#391A5F",
    alias: LayerAlias.SENSOR_FUSION
  },
  [LayerAlias.RGB]: {
    name: "RGB",
    color: "#FF0000",
    alias: LayerAlias.RGB
  },
  [LayerAlias.RGB2]: {
    name: "RGB2",
    color: "#FF7A00",
    alias: LayerAlias.RGB2,
    isUseFileManager: true
  },
  [LayerAlias.MULTISPECTRAL]: {
    name: "Multispectral",
    color: "#00CB50",
    alias: LayerAlias.MULTISPECTRAL
  },
  [LayerAlias.THERMAL]: {
    name: "Thermal",
    color: "#00D1FF",
    alias: LayerAlias.THERMAL,
    isUseFileManager: true
  },
  [LayerAlias.MAGNETIC]: {
    name: "Magnetic",
    color: "#00D1FF",
    alias: LayerAlias.MAGNETIC,
    isUseFileManager: true
  },
  [LayerAlias.MANUAL]: {
    name: "Manual",
    alias: LayerAlias.MANUAL,
    isCustom: true
  }
};

export const DEFAULT_LAYERS_LIST: LayerInterface[] = Object.values(LAYERS);

export enum UnitTypes {
  POINT = "Point",
  POLYGON = "Polygon"
}

export enum ManualPointTypes {
  UXO = "uxo",
  GCP = "gcp",
  TREE = "tree",
  STUMP = "stump",
  ROCK = "rock"
}

export enum PolygonColors {
  RED = "#FF0000",
  ORANGE = "#FF9800",
  GREEN = "#4CAF50",
  YELLOW = "#FFD900",
  BLUE = "#00E5FF80"
}

export interface PointTypeInterface {
  value: string;
  label: string;
  icon: string;
}

export const POINTS: Record<ManualPointTypes, PointTypeInterface> = {
  [ManualPointTypes.UXO]: {
    value: ManualPointTypes.UXO,
    label: "UXO - unexploded ordnance",
    icon: MineIcon
  },
  [ManualPointTypes.GCP]: {
    value: ManualPointTypes.GCP,
    label: "GCP (Ground Control Point)",
    icon: CrosshairIcon
  },
  [ManualPointTypes.TREE]: {
    value: ManualPointTypes.TREE,
    label: "Tree",
    icon: TreeIcon
  },
  [ManualPointTypes.STUMP]: {
    value: ManualPointTypes.STUMP,
    label: "Stump",
    icon: StumpIcon
  },
  [ManualPointTypes.ROCK]: {
    value: ManualPointTypes.ROCK,
    label: "Rock",
    icon: RockIcon
  }
};

export const DEFAULT_MANUAL_POINTS_LIST: PointTypeInterface[] =
  Object.values(POINTS);

export enum InferenceStatus {
  PREPARING = "preparing",
  PREPARED = "prepared",
  PENDING = "pending",
  PROCESSING = "processing",
  STOPPING = "stopping",
  DONE = "done",
  FAILED = "failed",
  STOPPED = "stopped",
  UPLOADING = "uploading"
}
export interface InferenceStatusInterface {
  alias: InferenceStatus;
  label: string;
  icon?: string;
  isIconRotating?: boolean;
}

export const INFERENCE_STATUSES: Record<
  InferenceStatus,
  InferenceStatusInterface
> = {
  [InferenceStatus.DONE]: {
    alias: InferenceStatus.DONE,
    label: "Complete",
    icon: CheckCheckIcon
  },
  [InferenceStatus.FAILED]: {
    alias: InferenceStatus.FAILED,
    label: "Error",
    icon: AlertTriangleIcon
  },
  [InferenceStatus.PREPARING]: {
    alias: InferenceStatus.PREPARING,
    label: "Preparing",
    icon: LoaderOrangeIcon,
    isIconRotating: true
  },
  [InferenceStatus.PREPARED]: {
    alias: InferenceStatus.PREPARED,
    label: "Prepared"
    // icon: LoaderGreyIcon
  },
  [InferenceStatus.PENDING]: {
    alias: InferenceStatus.PENDING,
    label: "In queue",
    icon: LoaderGreyIcon,
    isIconRotating: true
  },
  [InferenceStatus.PROCESSING]: {
    alias: InferenceStatus.PROCESSING,
    label: "In process",
    icon: LoaderGreyIcon,
    isIconRotating: true
  },
  [InferenceStatus.STOPPING]: {
    alias: InferenceStatus.STOPPING,
    label: "Cancellation ...",
    icon: LoaderGreyIcon,
    isIconRotating: true
  },
  [InferenceStatus.STOPPED]: {
    alias: InferenceStatus.STOPPED,
    label: "Inference canceled",
    icon: BanRedIcon
  },
  [InferenceStatus.UPLOADING]: {
    alias: InferenceStatus.UPLOADING,
    label: "Uploading",
    icon: BanRedIcon
  }
};

export const MULTISPECTRAL_OPTIONS = (min, max) => {
  //TODO remove minRescale and maxRescale. Default values for old projects
  const minRescale = min === 0 ? -0.0009298980003222823 : min;
  const maxRescale = max === 0 ? 0.05864158272743225 : max;
  return [
    {
      name: "RGB",
      params: `?rescale=${minRescale}%2C${maxRescale}`
    },
    {
      name: "NDVI",
      params:
        "?color_map=viridis&formula=NDVI&bands=auto&hillshade=&rescale=-0.551,0.26"
    },
    {
      name: "vNDVI",
      params:
        "?color_map=spectral_r&formula=vNDVI&bands=auto&hillshade=&rescale=0.56,0.79"
    },
    {
      name: "EXG",
      params:
        "?color_map=cividis&formula=EXG&bands=auto&hillshade=&rescale=-0.009427607990801334,0.019022520631551743"
    }
  ];
};

export const dmsRegex =
  /(\d{1,3})[°d]\s*(\d{1,2})['m]\s*(\d{1,2}(?:\.\d+)?)["s]?\s*([NSEW]?)/i;

export const ddRegex = /^-?\d+(\.\d+)?$/;

export enum UserRoles {
  USER = "user",
  HEAD = "head"
}
