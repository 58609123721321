import classNames from "classnames";
import { InferenceStatus, InferenceStatusInterface } from "constant";

import styles from "./inference-status-component.module.scss";

interface InferenceStatusComponentInterface {
  status: InferenceStatusInterface;
  completionPercentage: string;
}

const InferenceStatusComponent = ({
  status,
  completionPercentage
}: InferenceStatusComponentInterface) => {
  if (!status) return <></>;
  if (status?.alias === InferenceStatus.PREPARED) return <></>;
  return (
    <div className={styles["status"]}>
      {status.icon && (
        <div
          className={classNames(styles["status__icon"], {
            [styles["status__icon--rotating"]]: status.isIconRotating
          })}
        >
          <img src={status.icon} alt={`${status?.alias}-icon`} />
        </div>
      )}
      {status.label && (
        <label className={styles["status__label"]}>
          {`${status.label} ${
            completionPercentage ? completionPercentage + "%" : ""
          }`}
        </label>
      )}
    </div>
  );
};

export default InferenceStatusComponent;
