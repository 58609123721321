import classNames from "classnames";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { LayerAlias, UNITS_LIMIT, UnitTypes } from "constant";
import features from "features";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";
import ExpandBlock from "components/ExpandBlock/ExpandBlock";
import MapAreaListItem from "../MapAreaListItem/MapAreaListItem";
import MapLayer from "../MapLayer/MapLayer";

import VerticalDotsIcon from "assets/icons/dots-vertical.svg";
import ExpandedLayersIcon from "assets/icons/expanded-layers.svg";
import LayersIcon from "assets/icons/layers.svg";
import ReportIcon from "assets/icons/report.svg";

import styles from "./map-layers.module.scss";

interface MapLayersInterface {
  layers: any;
  aries: any;
  points: any;
  setView: any;
  unitsCollection: any;
  editUnitAction: any;
}
const MapLayers = ({
  layers,
  aries,
  setView,
  points,
  unitsCollection,
  editUnitAction
}: MapLayersInterface) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);
  const [isTransitionEnd, setIsTransitionEnd] = useState(true);

  const handleToggleOpenState = useCallback(() => {
    setIsOpen((prevState) => !prevState);
    setIsTransitionEnd(false);
  }, []);

  const handleTransitionEnd = useCallback((e) => {
    if (e.propertyName === "max-height") {
      setIsTransitionEnd(true);
    }
  }, []);

  const goToReport = useCallback(() => {
    navigate("report");
  }, [navigate]);

  // const layersWithoutPoints = useMemo(() => {
  //   return layers.filter((layer) => layer.alias !== LayerAlias.MANUAL);
  // }, [layers]);

  const onChangeLayerPointsActive = useCallback(
    (pointLayer) => () => {
      dispatch(
        features.unitsManager.actions.setActiveLayerPoints({
          value: pointLayer.value,
          isActive: !pointLayer.isActive
        })
      );
    },
    [dispatch]
  );

  const handleSeeAllUnits = useCallback(() => {
    dispatch(
      features.unitsManager.actions.fetchUnitsRequest({
        params: {
          projectId: id,
          limit: unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON].total,
          skip: UNITS_LIMIT,
          type: UnitTypes.POLYGON,
          alias: LayerAlias.MANUAL
        }
      })
    );
  }, [dispatch, id, unitsCollection]);

  return (
    <div
      className={classNames(styles["layers"], {
        [styles["layers--active"]]: isOpen
      })}
    >
      <div className={styles["layers__button"]}>
        <div
          className={styles["layers__button-small-container"]}
          onClick={handleToggleOpenState}
        >
          <span className={styles["layers__button-label"]}>Layers</span>
          <div className={styles["layer__button-icon"]}>
            <img
              className={styles["icon"]}
              src={LayersIcon}
              alt="layers-button"
            />
            <img
              className={styles["icon--active"]}
              src={ExpandedLayersIcon}
              alt="layers-button"
            />
          </div>
        </div>
        <div
          className={classNames(styles["layers-wrapper"], {
            [styles["layers-wrapper--scroll"]]: isOpen && isTransitionEnd
          })}
          onTransitionEnd={handleTransitionEnd}
        >
          <div className={styles["layers-content"]}>
            <div className={styles["layers-content"]}>
              {layers.map((layer, index) => (
                <div
                  className={styles["layers-container"]}
                  key={`layer-${index}`}
                >
                  <MapLayer {...layer} setView={setView} />
                </div>
              ))}
            </div>
            <ExpandBlock title="Areas">
              <div className={styles["layers-container"]}>
                {aries.map((layer, index) => (
                  <MapLayer
                    {...layer}
                    key={`layer-${index}`}
                    isPolygon
                    isInit
                  />
                ))}
                {unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON].units
                  .length > 0 && (
                  <div className={styles["areases-container"]}>
                    {unitsCollection[LayerAlias.MANUAL][
                      UnitTypes.POLYGON
                    ].units.map((el, index) => (
                      <MapAreaListItem
                        areaData={el}
                        editUnitAction={editUnitAction}
                        key={`${el.id}-${index}`}
                      />
                    ))}
                    {unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON]
                      .total > 10 &&
                      unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON]
                        .units.length !==
                        unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON]
                          .total && (
                        <div
                          className={styles["see-all-button"]}
                          onClick={handleSeeAllUnits}
                        >
                          <img src={VerticalDotsIcon} alt="dots" />
                          <span>
                            See all{" "}
                            {
                              unitsCollection[LayerAlias.MANUAL][
                                UnitTypes.POLYGON
                              ].total
                            }{" "}
                            aries
                          </span>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </ExpandBlock>
            <ExpandBlock title="Other">
              <div className={styles["layers-container"]}>
                <ShowHideButton
                  title={"Photos"}
                  onClick={() => {}}
                  isActive={false}
                  isDisabled={true}
                />
              </div>
            </ExpandBlock>
            <ExpandBlock title="Point">
              <div className={styles["layers-container"]}>
                {points.length > 0 ? (
                  points.map((item) => (
                    <ShowHideButton
                      title={item.label}
                      icon={item.icon}
                      onClick={onChangeLayerPointsActive(item)}
                      isActive={item.isActive}
                      key={`btn-${item.label}`}
                    />
                  ))
                ) : (
                  <label className={styles["no-points"]}>
                    No points added.
                  </label>
                )}
              </div>
            </ExpandBlock>
            <ExpandBlock title="Action">
              <div className={styles["layers-container"]}>
                <CommonButton
                  type="button"
                  icon={ReportIcon}
                  onClick={goToReport}
                >
                  Create report
                </CommonButton>
              </div>
            </ExpandBlock>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MapLayers;
