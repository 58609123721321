import cn from "classnames";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import useOutsideClick from "helpers/useOutsideClick";
import useAvailableTools from "hooks/useAvailableTools";

import LogOutButton from "components/buttons/LogOutButton/LogOutButton";
import NewProjectButton from "components/buttons/NewProjectButton/NewProjectButton";

import styles from "./burger-menu.module.scss";

interface BurgerMenuInterface {
  isActive: boolean;
  onClick: (option) => void;
}

const BurgerMenu = ({ isActive, onClick }: BurgerMenuInterface) => {
  const ref = useOutsideClick(onClick, isActive);
  const availableTools = useAvailableTools();

  useEffect(() => {
    const dropdown = ref.current;
    const rect = dropdown.getBoundingClientRect();
    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;
    //IMPORTANT paddings stored in container mixin
    const containerPadding =
      windowWidth > 1300 ? 40 : windowWidth > 992 ? 20 : 10;

    if (rect.bottom > windowHeight) {
      dropdown.style.top = `${windowHeight - rect.bottom}px`;
    }

    if (rect.right > windowWidth) {
      dropdown.style.left = `${windowWidth - rect.right - containerPadding}px`;
    }
  }, [ref]);

  return (
    <div
      className={cn(styles["dropdown"], {
        [styles["dropdown--active"]]: isActive
      })}
      ref={ref}
    >
      <div className={styles["head"]}>
        <NewProjectButton />
      </div>
      <div className={styles["divider"]} />

      {availableTools &&
        availableTools.map((item, i) => {
          return (
            <Link
              to={item.link}
              target={item.target}
              rel="noopener noreferrer"
              className={styles["option"]}
              key={`option${i}`}
            >
              {item.icon && (
                <div className={styles["option__icon"]}>
                  <img src={item.icon} alt={`{item.label}-icon`} />
                </div>
              )}
              {item.title}
            </Link>
          );
        })}
      <div className={styles["divider"]} />
      <LogOutButton />
    </div>
  );
};

export default BurgerMenu;
