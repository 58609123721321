import { UnitTypes } from "constant";
import features from "features";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const useDeleteMarker = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();

  const onDeleteMarker = useCallback(
    (markerId, alias) => () => {
      console.log(alias);
      dispatch(
        features.modal.actions.showModal({
          modalType: "DELETE",
          modalProps: {
            title: "Delete Marker?",
            subtitle:
              "Are you sure you want to delete this marker from the map? This action cannot be undone.",
            onAcceptClick: () =>
              // dispatch(
              //   features.map.actions.deleteMarkerRequest({
              //     params: { projectId, markerId },
              //     onSuccess: () => dispatch(features.modal.actions.hideModal())
              //   })
              // )

              dispatch(
                features.unitsManager.actions.deleteUnitRequest({
                  params: {
                    projectId,
                    unitId: markerId,
                    type: UnitTypes.POINT,
                    alias
                  },
                  onSuccess: () => dispatch(features.modal.actions.hideModal())
                })
              )
          }
        })
      );
    },
    [dispatch, projectId]
  );

  return onDeleteMarker;
};
export default useDeleteMarker;
