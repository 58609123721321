import { createSlice } from "@reduxjs/toolkit";
import { UserRoles } from "constant";

export interface TeamAndStaffInterface {
  isLoading: boolean;
  isFetchTeamLoading: boolean;
  isCreateTeamLoading: boolean;
  isDeleteTeamLoading: boolean;
  isEditTeamLoading: boolean;
  isCreateTeamEmployeeLoading: boolean;
  isEditTeamEmployeeLoading: boolean;
  isDeleteTeamEmployeeLoading: boolean;
  teams: any;
  teamsTotal: number;
  deputies: any;
}

const initialState: TeamAndStaffInterface = {
  isLoading: false,
  isFetchTeamLoading: false,
  isCreateTeamLoading: false,
  isDeleteTeamLoading: false,
  isEditTeamLoading: false,
  isCreateTeamEmployeeLoading: false,
  isEditTeamEmployeeLoading: false,
  isDeleteTeamEmployeeLoading: false,
  teams: [],
  teamsTotal: 0,
  deputies: []
};

export const mainSlice = createSlice({
  name: "teamAndStaff",
  initialState,
  reducers: {
    fetchDeputiesRequest: (state) => {
      state.isLoading = true;
    },
    fetchDeputiesSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.deputies =
        payload.users.list.filter(
          (user) => user?.role?.alias === UserRoles.HEAD
        ) ?? [];
    },
    fetchDeputiesError: (state) => {
      state.isLoading = false;
    },

    fetchTeamsRequest: (state) => {
      state.isLoading = true;
    },
    fetchTeamsSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.teams = payload.departments ?? [];
      state.teamsTotal = payload.total;
    },
    fetchTeamsError: (state) => {
      state.isLoading = false;
    },

    fetchTeamRequest: (state) => {
      state.isFetchTeamLoading = true;
    },
    fetchTeamSuccess: (state, { payload }) => {
      state.isFetchTeamLoading = false;
      state.teams = state.teams.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    fetchTeamError: (state) => {
      state.isFetchTeamLoading = false;
    },

    createTeamRequest: (state) => {
      state.isCreateTeamLoading = true;
    },
    createTeamSuccess: (state) => {
      state.isCreateTeamLoading = false;
      state.teamsTotal = state.teamsTotal + 1;
    },
    createTeamError: (state) => {
      state.isCreateTeamLoading = false;
    },

    editTeamRequest: (state) => {
      state.isEditTeamLoading = true;
    },
    editTeamSuccess: (state, { payload }) => {
      state.isEditTeamLoading = false;
      state.teams = state.teams.map((item) =>
        item.id === payload.id ? payload : item
      );
    },
    editTeamError: (state) => {
      state.isEditTeamLoading = false;
    },

    deleteTeamRequest: (state) => {
      state.isDeleteTeamLoading = true;
    },
    deleteTeamSuccess: (state) => {
      state.teamsTotal = state.teamsTotal - 1;
      state.isDeleteTeamLoading = false;
    },
    deleteTeamError: (state) => {
      state.isCreateTeamLoading = false;
    },

    createTeamEmployeeRequest: (state) => {
      state.isCreateTeamEmployeeLoading = true;
    },
    createTeamEmployeeSuccess: (state, { payload }) => {
      state.teams = state.teams.map((item) => {
        return item.id === payload.department_id
          ? {
              ...item,
              users: {
                list: [payload, ...(item.users?.list ?? [])],
                total: (item.users?.total ?? 0) + 1
              }
            }
          : item;
      });
      state.isCreateTeamEmployeeLoading = false;
    },
    createTeamEmployeeError: (state) => {
      state.isCreateTeamEmployeeLoading = false;
    },

    editTeamEmployeeRequest: (state) => {
      state.isEditTeamEmployeeLoading = true;
    },
    editTeamEmployeeSuccess: (state, { payload }) => {
      state.teams = state.teams.map((item) => {
        return item.id === payload.department_id
          ? {
              ...item,
              users: {
                list: item.users.list.map((user) =>
                  user.id === payload.id ? payload : item
                ),
                total: item.users.total
              }
            }
          : item;
      });
      state.isEditTeamEmployeeLoading = false;
    },
    editTeamEmployeeError: (state) => {
      state.isCreateTeamEmployeeLoading = false;
    },

    deleteTeamEmployeeRequest: (state) => {
      state.isDeleteTeamEmployeeLoading = true;
    },
    deleteTeamEmployeeSuccess: (state, { payload }) => {
      state.teams = state.teams.map((item) => {
        return item.id === payload.department_id
          ? {
              ...item,
              users: {
                list: item.users.list.filter((user) => user.id !== payload.id),
                total: item.users.total - 1
              }
            }
          : item;
      });
      state.isDeleteTeamEmployeeLoading = false;
    },
    deleteTeamEmployeeError: (state) => {
      state.isCreateTeamEmployeeLoading = false;
    },

    createTeamEmployeeCredentialsRequest: (state) => {
      state.isCreateTeamEmployeeLoading = true;
    },
    createTeamEmployeeCredentialsSuccess: (state) => {
      state.isCreateTeamEmployeeLoading = true;
    },
    createTeamEmployeeCredentialsError: (state) => {
      state.isCreateTeamEmployeeLoading = true;
    }
  }
});

export const { actions, reducer } = mainSlice;
