import classNames from "classnames";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import features from "features";
import useTeamEmployeeHandler from "hooks/useTeamEmployeeHandler";

import Accordion from "components/Accordion/Accordion";
import CommonButton from "components/buttons/CommonButton/CommonButton";
import IconButton from "components/buttons/IconButton/IconButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";

import ChevronIcon from "assets/icons/chevron-down.svg";
import EditIcon from "assets/icons/pencil.svg";
import PlusIcon from "assets/icons/plus.svg";
import DeleteIcon from "assets/icons/trash-2.svg";

import FormField from "components/inputs/FormField/FormField";
import SmallLoader from "components/SmallLoader/SmallLoader";
import Table from "components/Table/Table";

import { UserRoles } from "constant";
import styles from "./team.module.scss";

interface TeamInterface {
  name: string;
  children: any;
  id: string;
  orgId: string;
  users: any;
  // updateTeamsList: any;
}

const Team = ({ name, id, orgId, users }: TeamInterface) => {
  const dispatch = useDispatch();

  const { createTeamEmployee, editTeamEmployee } = useTeamEmployeeHandler({
    orgId,
    id
  });

  const [isLoading, setIsLoading] = useState(false);

  const onDelete = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "DELETE",
        modalProps: {
          title: "Delete team?",
          subtitle: `Are you sure you want to delete team "${name}"? This action cannot be undone.`,
          onAcceptClick: () => {
            dispatch(
              features.teamAndStaff.actions.deleteTeamRequest({
                params: { org_id: orgId, id }
              })
            );
            dispatch(features.modal.actions.hideModal());
          }
        }
      })
    );
  }, [dispatch, id, name, orgId]);

  const onSubmitEdit = useCallback(
    (val: { name: string }) => {
      const { name } = val;
      dispatch(
        features.teamAndStaff.actions.editTeamRequest({
          params: { org_id: orgId, id },
          fields: { name }
        })
      );
      dispatch(features.modal.actions.hideModal());
    },
    [dispatch, id, orgId]
  );

  const onEdit = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "FORM",
        modalProps: {
          title: "Edit Team",
          description: "Please enter new name.",
          initialValues: { name },
          onSubmit: onSubmitEdit,
          validationSchema: Yup.object().shape({
            name: Yup.string().required("Team name is required")
          }),
          acceptButtonLabel: "Send",
          formContent: (
            <>
              <FormField name="name" placeholder="Team name" />
              <div className={styles["edit-actions"]}>
                <h4 className={styles["edit-actions__title"]}>Manage</h4>
                <CommonButton icon={DeleteIcon} onClick={onDelete}>
                  Delete team
                </CommonButton>
              </div>
            </>
          )
        }
      })
    );
  }, [dispatch, name, onDelete, onSubmitEdit]);

  const onAddStaff = useCallback(() => {
    createTeamEmployee(UserRoles.USER);
  }, [createTeamEmployee]);

  const onEditStaff = useCallback(
    (id) => () => {
      const user = users.list.find((user) => user.id === id);

      editTeamEmployee(user);
    },
    [editTeamEmployee, users]
  );

  const onAsyncToggleAccordion = useCallback(
    (onToggleEvent, dep_id, isOpen) => () => {
      if (!isOpen) {
        setIsLoading(true);
        dispatch(
          features.teamAndStaff.actions.fetchTeamRequest({
            params: { org_id: orgId, dep_id },
            onSuccess: () => {
              onToggleEvent();
              setIsLoading(false);
            },
            onError: () => {
              setIsLoading(false);
            }
          })
        );
      } else onToggleEvent();
    },
    [dispatch, orgId]
  );

  return (
    <div className={styles["team"]}>
      <Accordion
        noScroll
        header={({ isOpen, onToggleAccordion }) => (
          <div
            className={classNames(styles["team__header"], {
              [styles["team__header--active"]]: isOpen
            })}
          >
            {name}
            {isLoading && <SmallLoader />}
            <IconButton
              className={styles["header__button"]}
              onClick={onAsyncToggleAccordion(onToggleAccordion, id, isOpen)}
              icon={ChevronIcon}
            />
          </div>
        )}
      >
        <div className={styles["team__body"]}>
          <div className={styles["body__top"]}>
            <div className={styles["staff"]}>
              <p>Staff:</p>
              <label>{users?.total}</label>
            </div>
            <div className={styles["actions"]}>
              <PrimaryButton icon={PlusIcon} onClick={onAddStaff}>
                Add staff
              </PrimaryButton>
              <CommonButton icon={EditIcon} onClick={onEdit}>
                Edit
              </CommonButton>
            </div>
          </div>
          {users?.total > 0 && (
            <Table
              className={styles["users-table"]}
              headers={[
                {
                  title: "Name",
                  alias: "name",
                  thClass: styles["th-styles"],
                  tdClass: styles["td-styles"]
                },
                {
                  title: "Position",
                  alias: "role",
                  thClass: styles["th-styles"],
                  tdClass: styles["td-styles"]
                },
                {
                  title: "Actions",
                  alias: "id",
                  thClass: styles["th-styles"],
                  tdClass: styles["td-styles"],
                  element: (val) => (
                    <CommonButton icon={EditIcon} onClick={onEditStaff(val)}>
                      Edit
                    </CommonButton>
                  )
                }
              ]}
              maxHeight={310}
              body={users?.list.map((item) => {
                return { ...item, role: item.role?.name };
              })}
            />
          )}
        </div>
      </Accordion>
    </div>
  );
};
export default Team;
