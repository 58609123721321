import {
  DEFAULT_MANUAL_POINTS_LIST,
  LayerAlias,
  PolygonColors,
  UnitTypes
} from "constant";
import { UnitsInterface, UnitsManagerStateInterface } from "./interfaces";

export const initUnitsData: UnitsInterface = {
  [UnitTypes.POINT]: { units: [], total: 0 },
  [UnitTypes.POLYGON]: { units: [], total: 0 }
};

export const initialState: UnitsManagerStateInterface = {
  isUnitCreateLoading: false,
  isUnitDeleteLoading: false,
  isFetchUnitsLoading: false,
  unitsCollection: {
    [LayerAlias.RGB]: initUnitsData,
    [LayerAlias.RGB2]: initUnitsData,
    [LayerAlias.MULTISPECTRAL]: initUnitsData,
    [LayerAlias.THERMAL]: initUnitsData,
    [LayerAlias.MAGNETIC]: initUnitsData,
    [LayerAlias.SENSOR_FUSION]: initUnitsData,
    [LayerAlias.MANUAL]: initUnitsData
  },

  editedPolygonParameters: {
    name: "Pole 1",
    layer: LayerAlias.MANUAL,
    geo_data: {
      type: "Polygon",
      coordinates: []
    },
    additional: {
      area: "",
      stroke_color: PolygonColors.RED,
      stroke_transparency: "1",
      fill_color: PolygonColors.RED,
      fill_transparency: "0.5"
    },
    isVisible: true
  },
  manualPointLayers: DEFAULT_MANUAL_POINTS_LIST
};
