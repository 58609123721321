import { requestMiddleware } from "helpers/api";
import { takeLatest } from "redux-saga/effects";
import { actions } from "./ducks";
import {
  addEmployee,
  addEmployeeCredentials,
  addTeam,
  updateEmployee,
  getTeam,
  getTeams,
  removeEmployee,
  removeTeam,
  updateTeam
} from "services/api/auth";

function* fetchDeputies({ payload }) {
  const request = getTeam;

  const { fetchDeputiesSuccess: setSuccess, fetchDeputiesError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchTeams({ payload }) {
  const request = getTeams;

  const { fetchTeamsSuccess: setSuccess, fetchTeamsError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* fetchTeam({ payload }) {
  const request = getTeam;

  const { fetchTeamSuccess: setSuccess, fetchTeamError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* createTeam({ payload }) {
  const request = addTeam;

  const { createTeamSuccess: setSuccess, createTeamError: setError } = actions;

  function* onSuccess(res) {
    if (payload.onSuccess) yield payload.onSuccess(res);

    //TODO move inside TeamList component
    // const newPayload: any = {
    //   params: {
    //     org_id: payload.params.org_id,
    //     dep_id: payload.fields.parent_id,
    //     skip: 0,
    //     limit: 6
    //   }
    // };

    // yield put(yield fetchTeamsRequest(newPayload));
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}
function* editTeam({ payload }) {
  const request = updateTeam;

  const { editTeamSuccess: setSuccess, editTeamError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* deleteTeam({ payload }) {
  const request = removeTeam;

  const { deleteTeamSuccess: setSuccess, deleteTeamError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* createTeamEmployee({ payload }) {
  const request = addEmployee;

  const {
    createTeamEmployeeSuccess: setSuccess,
    createTeamEmployeeError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* editTeamEmployee({ payload }) {
  const request = updateEmployee;

  const {
    editTeamEmployeeSuccess: setSuccess,
    editTeamEmployeeError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* deleteTeamEmployee({ payload }) {
  const request = removeEmployee;

  const {
    deleteTeamEmployeeSuccess: setSuccess,
    deleteTeamEmployeeError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}
function* createTeamEmployeeCredentials({ payload }) {
  const request = addEmployeeCredentials;

  const {
    createTeamEmployeeCredentialsSuccess: setSuccess,
    createTeamEmployeeCredentialsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.createTeamRequest, createTeam);
  yield takeLatest(actions.fetchDeputiesRequest, fetchDeputies);
  yield takeLatest(actions.fetchTeamsRequest, fetchTeams);
  yield takeLatest(actions.fetchTeamRequest, fetchTeam);
  yield takeLatest(actions.editTeamRequest, editTeam);
  yield takeLatest(actions.deleteTeamRequest, deleteTeam);
  yield takeLatest(actions.createTeamEmployeeRequest, createTeamEmployee);
  yield takeLatest(actions.editTeamEmployeeRequest, editTeamEmployee);
  yield takeLatest(actions.deleteTeamEmployeeRequest, deleteTeamEmployee);
  yield takeLatest(
    actions.createTeamEmployeeCredentialsRequest,
    createTeamEmployeeCredentials
  );
}
