export const ensureClosedPolygon = (coordinates) => {
  const newCoordinates = [...coordinates]
  if (
    newCoordinates[0][0] !== newCoordinates[newCoordinates.length - 1][0] ||
    newCoordinates[0][1] !== newCoordinates[newCoordinates.length - 1][1]
  ) {
    newCoordinates.push(newCoordinates[0]);
  }

  return newCoordinates;
};
