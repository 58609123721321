import classNames from "classnames";
import { useMemo } from "react";
import { NavLink, Route, Routes } from "react-router-dom";

import TeamAndStaffPage from "scenes/TeamAndStaffPage/_TeamAndStaffPage/_TeamAndStaffPage";

import ProfileIcon from "assets/icons/user-circle-2.svg";
import DepartmentAndStuffIcon from "assets/icons/users-2.svg";

import styles from "./settings-page.module.scss";

const SettingsPage = () => {
  const sideBarOptions = useMemo(() => {
    return [
      {
        title: "Department and staff",
        to: "department-and-staff",
        icon: DepartmentAndStuffIcon
      },
      {
        title: "Profile",
        to: "profile",
        icon: ProfileIcon
      }
    ];
  }, []);

  return (
    <div className={styles["settings-page"]}>
      <div className={styles["side-bar"]}>
        <div className={styles["side-bar__title"]}>Settings</div>
        <div className={styles["side-bar__options"]}>
          {sideBarOptions.map((item) => (
            <NavLink
              className={({ isActive }) =>
                classNames(styles["option"], {
                  [styles[`option--active`]]: isActive
                })
              }
              to={item.to}
              key={`sidebar-option-${item.title}`}
            >
              <div className={styles["option__icon"]}>
                <img src={item.icon} alt="settings-page-icon" />
              </div>
              <div className={styles["option__title"]}>{item.title}</div>
            </NavLink>
          ))}
        </div>
      </div>
      <div className={styles["divider"]} />
      <div className={styles["settings"]}>
        <Routes>
          <Route path="/department-and-staff" element={<TeamAndStaffPage />} />
          <Route path="/profile" element={<div></div>} />
        </Routes>
      </div>
    </div>
  );
};

export default SettingsPage;
