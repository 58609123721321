import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import features from "features";

import IconButton from "components/buttons/IconButton/IconButton";
import { LayerAlias, UNITS_LIMIT, UnitTypes } from "constant";

import PencilIcon from "assets/icons/edit-pencil.svg";
import EyeCloseIcon from "assets/icons/eye-close.svg";
import EyeOpenIcon from "assets/icons/eye-open.svg";
import { ReactComponent as AreaIcon } from "assets/icons/square-area-black.svg";
import DeleteIcon from "assets/icons/trash-3.svg";

import styles from "./map-area-list-item.module.scss";

interface MapAreaListItemInterface {
  areaData: any;
  editUnitAction?: any;
}

const MapAreaListItem = ({
  areaData,
  editUnitAction
}: MapAreaListItemInterface) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleSwitchAreaVisibility = useCallback(
    (areaData) => () => {
      const { id, isVisible } = areaData;

      dispatch(
        features.unitsManager.actions.setPolygonUnitVisibility({
          id,
          isVisible: !isVisible
        })
      );
    },
    [dispatch]
  );

  const handleEditArea = useCallback(
    (data) => () => {
      editUnitAction(data);
    },
    [editUnitAction]
  );

  const onUnitDelete = useCallback(
    (unit) => () => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "DELETE",
          modalProps: {
            title: "Delete area?",
            subtitle: `Are you sure you want to delete area "${unit.name}"? This action cannot be undone.`,
            onAcceptClick: () => {
              dispatch(
                features.unitsManager.actions.deleteUnitRequest({
                  params: {
                    projectId: id,
                    unitId: unit.id,
                    type: UnitTypes.POLYGON,
                    alias: LayerAlias.MANUAL
                  },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                    dispatch(
                      features.unitsManager.actions.fetchUnitsRequest({
                        params: {
                          projectId: id,
                          limit: UNITS_LIMIT,
                          skip: 0,
                          type: UnitTypes.POLYGON,
                          alias: LayerAlias.MANUAL
                        }
                      })
                    );
                    dispatch(
                      features.unitsManager.actions.clearEditPolygonParameters()
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [dispatch, id]
  );

  return (
    <div className={styles["area-list-item"]}>
      <span className={styles["area-name"]}>{areaData.name}</span>
      <div className={styles["external-area-controls-container"]}>
        <IconButton
          icon={
            <AreaIcon
              style={{
                fill: areaData.additional.fill_color,
                fillOpacity: areaData.additional.fill_transparency
              }}
            />
          }
          className={styles["color-button"]}
          alt={areaData.additional.fill_color}
          type="button"
        />
        <div className={styles["area-controls"]}>
          <IconButton
            icon={areaData.isVisible ? EyeOpenIcon : EyeCloseIcon}
            className={styles["icon-button"]}
            onClick={handleSwitchAreaVisibility(areaData)}
          />
          <IconButton
            icon={PencilIcon}
            className={styles["icon-button"]}
            onClick={handleEditArea(areaData)}
          />
          <IconButton
            icon={DeleteIcon}
            className={styles["icon-button"]}
            onClick={onUnitDelete(areaData)}
          />
        </div>
      </div>
    </div>
  );
};

export default MapAreaListItem;
