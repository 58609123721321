import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import features from "features";

import TeamEmployeeForm from "components/modalForms/TeamEmployeeForm/TeamEmployeeForm";
import { UserRoles } from "constant";

const DEFAULT_INITIAL_VALUES = {
  name: "",
  surname: ""
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  surname: Yup.string().required("Surname is required")
});

const useTeamEmployeeHandler = ({ orgId, id }) => {
  const dispatch = useDispatch();

  const createTeamEmployee = useCallback(
    (role: UserRoles) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Add a new employee",
            description:
              "Enter the necessary information to create an employee profile.",
            onSubmit: (values) => {
              dispatch(
                features.modal.actions.updateModal({
                  modalProps: {
                    isLoading: true,
                    formContent: <TeamEmployeeForm isLock />
                  }
                })
              );
              dispatch(
                features.teamAndStaff.actions.createTeamEmployeeRequest({
                  params: { org_id: orgId, id },
                  fields: { ...values, role },
                  onSuccess: (res) => {
                    dispatch(
                      features.modal.actions.updateModal({
                        modalProps: {
                          title: "Success!",
                          description:
                            "The employee profile has been successfully created.Copy the key.",
                          isLoading: false,
                          isDone: true,
                          formContent: (
                            <>
                              <TeamEmployeeForm
                                isLock
                                credentials={{
                                  password: res.password,
                                  key: res.key
                                }}
                              />
                            </>
                          )
                        }
                      })
                    );
                  },
                  onError: () => {
                    dispatch(
                      features.modal.actions.updateModal({
                        modalProps: {
                          isLoading: false,
                          formContent: <TeamEmployeeForm />
                        }
                      })
                    );
                  }
                })
              );
            },
            acceptButtonLabel: "Generate key",
            validationSchema,
            initialValues: DEFAULT_INITIAL_VALUES,
            formContent: <TeamEmployeeForm />
          }
        })
      );
    },
    [dispatch, id, orgId]
  );

  const onDeleteTeamEmployee = useCallback(
    (userId) => () => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "DELETE",
          modalProps: {
            title: "Delete employee?",
            subtitle: `Are you sure you want to delete user? This action cannot be undone.`,
            onAcceptClick: () => {
              dispatch(
                features.teamAndStaff.actions.deleteTeamEmployeeRequest({
                  params: { org_id: orgId, id, userId }
                })
              );
              dispatch(features.modal.actions.hideModal());
            }
          }
        })
      );
    },
    [dispatch, id, orgId]
  );

  const onRegenerateCredentials = useCallback(
    (employeeId) => () => {
      dispatch(
        features.modal.actions.updateModal({
          modalProps: {
            isLoading: true
          }
        })
      );

      dispatch(
        features.teamAndStaff.actions.createTeamEmployeeCredentialsRequest({
          params: { org_id: orgId, id, userId: employeeId },
          onSuccess: (res) => {
            dispatch(
              features.modal.actions.updateModal({
                modalProps: {
                  isLoading: false,
                  formContent: (
                    <TeamEmployeeForm
                      credentials={{ password: res.password, key: res.key }}
                      onDelete={onDeleteTeamEmployee(employeeId)}
                    />
                  )
                }
              })
            );
          }
        })
      );
    },
    [dispatch, id, onDeleteTeamEmployee, orgId]
  );

  const editTeamEmployee = useCallback(
    (defaultData) => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Edit employee",
            description:
              "Enter the necessary information to create an employee profile",
            onSubmit: (values) => {
              dispatch(
                features.modal.actions.updateModal({
                  modalProps: {
                    isLoading: true
                  }
                })
              );

              dispatch(
                features.teamAndStaff.actions.editTeamEmployeeRequest({
                  params: { org_id: orgId, id, userId: defaultData.id },
                  fields: { ...values, role: "developer" },
                  onSuccess: () => {
                    dispatch(features.modal.actions.hideModal());
                  },
                  onError: () => {
                    dispatch(
                      features.modal.actions.updateModal({
                        modalProps: {
                          isLoading: false
                        }
                      })
                    );
                  }
                })
              );
            },
            acceptButtonLabel: "Save",
            validationSchema,
            initialValues: defaultData,
            formContent: (
              <TeamEmployeeForm
                onRegenerateCredentials={onRegenerateCredentials(
                  defaultData.id
                )}
                onDelete={onDeleteTeamEmployee(defaultData.id)}
              />
            )
          }
        })
      );
    },
    [dispatch, id, onDeleteTeamEmployee, onRegenerateCredentials, orgId]
  );

  return {
    createTeamEmployee,
    editTeamEmployee
  };
};

export default useTeamEmployeeHandler;
