import { useDispatch } from "react-redux";

import {
  InferenceStatus,
  InferenceStatusInterface,
  LayerAlias
} from "constant";
import features from "features";
import { useCallback, useMemo } from "react";

import CommonButton from "components/buttons/CommonButton/CommonButton";

import BanIcon from "assets/icons/ban.svg";
import PlayIcon from "assets/icons/play.svg";
import RepeatIcon from "assets/icons/repeat.svg";

// import classNames from "classnames";
// import styles from "./inference-status-component.module.scss";

interface InferenceActionInterface {
  id: string;
  providerId: string;
  status: InferenceStatusInterface;
  alias: LayerAlias;
  isLoading?: boolean;
  disabled?: boolean;
}

interface ActionButtonDataInterface {
  icon?: string;
  label: string;
  isDisabled?: boolean;
  onAction?: () => void;
  isLoading?: boolean;
}

const InferenceAction = ({
  id,
  providerId,
  status,
  alias,
  isLoading,
  disabled
}: InferenceActionInterface) => {
  const dispatch = useDispatch();

  const onStartInference = useCallback(() => {
    dispatch(
      features.layersManager.actions.startProviderInferenceRequest({
        params: { id, alias, providerId }
      })
    );
  }, [alias, dispatch, id, providerId]);

  const onCancelUploading = useCallback(() => {
    dispatch(
      features.layersManager.actions.cancelUploadingInferenceFile({
        params: { id, providerId }
      })
    );
  }, [dispatch, id, providerId]);

  const onCancelInference = useCallback(() => {
    dispatch(
      features.layersManager.actions.cancelProviderInferenceRequest({
        params: { id, alias, providerId }
      })
    );
  }, [dispatch, id, providerId, alias]);

  const actionButtonData = useMemo((): Record<
    InferenceStatus,
    ActionButtonDataInterface
  > => {
    return {
      [InferenceStatus.DONE]: {
        icon: RepeatIcon,
        label: "Restart inference",
        onAction: onStartInference
      },
      [InferenceStatus.FAILED]: {
        icon: PlayIcon,
        label: "Start inference",
        onAction: onStartInference
      },
      [InferenceStatus.PREPARING]: {
        icon: PlayIcon,
        label: "Cancel inference"
      },
      [InferenceStatus.PREPARED]: {
        icon: PlayIcon,
        label: "Start inference",
        onAction: onStartInference
      },
      [InferenceStatus.PENDING]: {
        icon: BanIcon,
        label: "Cancel inference",
        onAction: onCancelInference
      },
      [InferenceStatus.PROCESSING]: {
        icon: BanIcon,
        label: "Cancel inference",
        onAction: onCancelInference
      },
      [InferenceStatus.STOPPING]: {
        icon: BanIcon,
        label: "Cancel inference ",
        isDisabled: true
      },
      [InferenceStatus.STOPPED]: {
        icon: PlayIcon,
        label: "Start inference",
        onAction: onStartInference
      },
      [InferenceStatus.UPLOADING]: {
        label: "Cancel uploading",
        onAction: onCancelUploading,
        isLoading: true
      }
    };
  }, [onCancelInference, onCancelUploading, onStartInference]);

  if (!status) return <></>;

  return (
    <CommonButton
      icon={actionButtonData[status.alias].icon}
      onClick={actionButtonData[status.alias].onAction}
      disabled={
        actionButtonData[status.alias].isDisabled || isLoading || disabled
      }
      isLoading={isLoading || actionButtonData[status.alias].isLoading}
    >
      {actionButtonData[status.alias].label}
    </CommonButton>
  );
};

export default InferenceAction;
