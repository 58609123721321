import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const settings = (props: any): JSX.Element => {
  return (
    <Layout backPath="/" isFullFill>
      <features.settings.page {...props} />
    </Layout>
  );
};

export default settings;
