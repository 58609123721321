import { put, takeEvery, takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import {
  addUnit,
  getUnit,
  getUnits,
  putUnit,
  removeUnit
} from "services/api/units";
import { actions } from "./ducks";
import { UnitTypes } from "constant";
import features from "features";

function* fetchUnits({ payload }) {
  const request = getUnits;
  const { fetchUnitsSuccess: setSuccess, fetchUnitsError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}
function* fetchUnit({ payload }) {
  const request = getUnit;
  const { fetchUnitSuccess: setSuccess, fetchUnitError: setError } = actions;

  function* onSuccess(res) {
    yield put(
      features.map.actions.setPointDetails({
        ...res.data,
        alias: payload.params.alias
      })
    );
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess,
    isReturnRequestPayload: true
  });
}

function* createUnit({ payload }) {
  const request = addUnit;
  const {
    createUnitSuccess: setSuccess,
    createUnitError: setError,
    setActiveLayerPoints,
    setPolygonUnitVisibility
  } = actions;

  function* onSuccess(res) {
    if (payload.onSuccess) yield payload.onSuccess(res);

    switch (res.params.type) {
      case UnitTypes.POLYGON:
        yield put(
          setPolygonUnitVisibility({
            id: res.data.id,
            isVisible: true
          })
        );
        break;
      case UnitTypes.POINT:
        yield put(
          setActiveLayerPoints({
            value: res.data.additional.object_type,
            isActive: true
          })
        );
        break;

      default:
        break;
    }
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess,
    isReturnRequestPayload: true
  });
}

function* updateUnit({ payload }) {
  const request = putUnit;
  const { updateUnitSuccess: setSuccess, updateUnitError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* deleteUnit({ payload }) {
  const request = removeUnit;
  const { deleteUnitSuccess: setSuccess, deleteUnitError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

export default function* watchSaga() {
  yield takeLatest(actions.createUnitRequest, createUnit);
  yield takeEvery(actions.fetchUnitsRequest, fetchUnits);
  yield takeEvery(actions.fetchUnitRequest, fetchUnit);
  yield takeLatest(actions.updateUnitRequest, updateUnit);
  yield takeLatest(actions.deleteUnitRequest, deleteUnit);
}
