import { createSlice } from "@reduxjs/toolkit";

export interface IModalState {
  modalType: string | null;
  modalProps: any;
  closeKey?: string;
  isModalOpened: boolean;
}

export const initialState: IModalState = {
  modalType: null,
  modalProps: {},
  isModalOpened: false,
  closeKey: ""
};

export const mainSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, { payload }) => {
      state.modalType = payload.modalType;
      state.modalProps = payload.modalProps;
      state.closeKey = payload.closeKey;
      state.isModalOpened = true;
    },
    updateModal: (state, { payload }) => {
      state.modalProps = { ...state.modalProps, ...payload.modalProps };
    },
    hideModal: (state, { payload }) => {
      if (state.closeKey && payload !== state.closeKey) return;

      if (state.closeKey) state.closeKey = "";

      state.modalType = initialState.modalType;
      state.modalProps = initialState.modalProps;
      state.isModalOpened = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
