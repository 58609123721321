import cn from "classnames";

import SmallLoader from "components/SmallLoader/SmallLoader";

import styles from "./primary-button.module.scss";

interface PrimaryButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: string;
  onClick?: () => void;
  icon?: string;
  textSize?: number;
  className?: string;
  isDisable?: boolean;
  isLoading?: boolean;
  isLarge?: boolean;
}

const PrimaryButton = ({
  type,
  children,
  onClick,
  icon,
  textSize,
  className,
  isDisable,
  isLoading,
  isLarge
}: PrimaryButtonInterface) => {
  return (
    <button
      type={type}
      className={cn(
        styles["primary"],
        className,
        {
          [styles["primary--disable"]]: isDisable
        },
        { [styles["primary--large"]]: isLarge }
      )}
      style={{ fontSize: textSize }}
      onClick={onClick}
      disabled={isDisable}
    >
      {isLoading ? (
        <SmallLoader isLight />
      ) : (
        <>
          {icon && (
            <div className={styles["primary__icon"]}>
              <img src={icon} alt={children + "-icon"} />
            </div>
          )}
        </>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
