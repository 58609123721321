import { useParams } from "react-router-dom";

import features from "features";

import Layout from "scenes/Layout/_Layout/_Layout";

const Report = (props: any): JSX.Element => {
  const { id } = useParams();
  const backPath = `/map/${id}`;

  return (
    <Layout title="Project report" backPath={backPath} isWithoutSettings>
      <features.projectReport.page {...props} />
    </Layout>
  );
};

export default Report;
