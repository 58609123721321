import {
  AUTH_URL,
  EXTERNAL_API_URL,
  GEO_VISION_API_URL,
  INFERENCE_API_URL,
  ODM_API_URL
} from "../constant";

import ApiProvider from "./ApiProvider";

export const ODMProvider = ApiProvider(ODM_API_URL);

export const MapProvider = ApiProvider(GEO_VISION_API_URL);

export const ExternalProvider = ApiProvider(EXTERNAL_API_URL);

export const PDFProvider = ApiProvider("https://pdf.vision.dropla.tech");

export const InferenceProvider = ApiProvider(INFERENCE_API_URL);

export const AuthProvider = ApiProvider(AUTH_URL);

// TODO create more elegant solution :D
export const updateProvidersToken = (token) => {
  ODMProvider.setInterceptors(token);
  MapProvider.setInterceptors(token);
  ExternalProvider.setInterceptors(token);
  InferenceProvider.setInterceptors(token);
  AuthProvider.setInterceptors(token);
};
