import { createSlice } from "@reduxjs/toolkit";
import { AREA_COLORS } from "constant";

export interface MapAriesInterface {
  radius: number;
  name: string;
  color: string;
  isActive: boolean;
  alias: string;
}

interface GeoDataInterface {
  type: string;
  coordinates: number[];
}

export interface MarkerInterface {
  color: string;
  created_at: string;
  geo_data: GeoDataInterface;
  id: string;
  layer: string;
  name: string;
  additional: {
    probability: number;
    object_type?: string;
  };
}

export interface PolygonInterface {
  coordinates: number[];
  id: string;
  alias: string;
  color: string;
  text: string;
  isActive?: boolean;
}

export interface MapStateInterface {
  isProjectLoading: boolean;
  isPolygonsLoading: boolean;
  isMarkersLoading: boolean;
  isMarkerDetailsLoading: boolean;
  isLayerCreateLoading: boolean;
  isMarkerCreateLoading: boolean;
  isMarkerDeleteLoading: boolean;
  title: string;
  id: string;
  // markers: MarkerInterface[];
  aries: MapAriesInterface[];
  polygons: MarkerInterface[];
  markerDetails: MarkerDetailsInterface;
}

export interface MarkerDetailsInterface {
  alias(id: string, alias: any): () => void;
  name: string;
  id: string;
  images: string[];
  geo_data: {
    type: string;
    coordinates: number[];
  };
  isActive?: boolean;
  wikiData?: any;
}

export enum InferenceStatus {
  PREPARING = "preparing",
  PREPARED = "prepared",
  PENDING = "pending",
  PROCESSING = "processing",
  STOPPING = "stopping",
  DONE = "done",
  FAILED = "failed",
  STOPPED = "stopped"
}

const initialState: MapStateInterface = {
  isProjectLoading: false,
  isPolygonsLoading: false,
  isMarkersLoading: false,
  isLayerCreateLoading: false,
  isMarkerDetailsLoading: false,
  isMarkerDeleteLoading: false,
  isMarkerCreateLoading: false,
  title: "",
  id: "",
  aries: AREA_COLORS,
  polygons: [],
  markerDetails: null
};

export const mainSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    fetchProjectRequest: (state) => {
      state.isProjectLoading = true;
    },
    fetchProjectSuccess: (state, { payload }) => {
      state.isProjectLoading = false;
      state.title = payload.name;
      state.id = payload.id;
    },
    fetchProjectError: (state) => {
      state.isProjectLoading = false;
    },

    setPointDetails: (state, { payload }) => {
      state.markerDetails = {
        ...state.markerDetails,
        ...payload,
        isActive: true,
        wikiData: undefined
      };
    },

    clearPointDetails: (state) => {
      state.markerDetails = { ...state.markerDetails, isActive: false };
    },

    fetchPointWikiDetailsRequest: (state, _) => {
      state.isMarkerDetailsLoading = true;
    },
    fetchPointWikiDetailsSuccess: (state, { payload }) => {
      state.isMarkerDetailsLoading = false;
      state.markerDetails = { ...state.markerDetails, wikiData: payload[0] };
    },
    fetchPointWikiDetailsError: (state) => {
      state.isMarkerDetailsLoading = false;
    },

    toggleAriaIsActive: (state, { payload }) => {
      state.aries = state.aries.map((item) =>
        item.alias === payload
          ? { ...item, isActive: payload.isActive ?? !item.isActive }
          : item
      );
    },

    clearState: () => {
      return { ...initialState };
    }
  }
});

export const { actions, reducer } = mainSlice;
