import cookie from "js-cookie";

// import { PRIVATE_KEY } from "constant";
const crypto = window.crypto.subtle;

function stringToArrayBuffer(str) {
  const encoder = new TextEncoder();
  return encoder.encode(str);
}

const base64UrlEncode = (data) => {
  const base64 = btoa(String.fromCharCode(...new Uint8Array(data)));
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// function parsePrivateKey(keyData) {
//   const pemHeader = "-----BEGIN PRIVATE KEY-----";
//   const pemFooter = "-----END PRIVATE KEY-----";
//   const firstLineEndIndex = keyData.indexOf(pemHeader);
//   const uid = keyData.substring(0, firstLineEndIndex).trim();

//   const pemContents = keyData.substring(
//     firstLineEndIndex + pemHeader.length,
//     keyData.length - pemFooter.length - 1
//   );
//   const binaryDerString = window.atob(pemContents.trim());

//   const binaryDer = str2ab(binaryDerString);

//   const privateKey = window.crypto.subtle.importKey(
//     "pkcs8",
//     binaryDer,
//     { name: "ECDSA", namedCurve: "P-256" },
//     true,
//     ["sign"]
//   );
//   return { privateKey, uid };
// }
async function parsePrivateKey(keyData) {
  const pemHeader = "-----BEGIN PRIVATE KEY-----";
  const pemFooter = "-----END PRIVATE KEY-----";
  const firstLineEndIndex = keyData.indexOf(pemHeader);
  const uid = keyData.substring(0, firstLineEndIndex).trim();
  const pemContents = keyData.substring(
    firstLineEndIndex + pemHeader.length,
    keyData.length - pemFooter.length - 1
  );

  const binaryDerString = window.atob(pemContents.trim());
  const binaryDer = str2ab(binaryDerString);
  const privateKey = await window.crypto.subtle
    .importKey(
      "pkcs8",
      binaryDer,
      { name: "RSASSA-PKCS1-v1_5", hash: "SHA-512" },
      true,
      ["sign"]
    )
    .catch((e) => {
      console.log(e);
    });

  return { privateKey, uid };
}

export const createClientToken = async ({ keyFileData, password, long }) => {
  const header = {
    alg: "RS512",
    typ: "JWT"
  };

  const { uid, privateKey } = await parsePrivateKey(keyFileData);

  const payload = {
    uid,
    password,
    long,
    iss: "user",
    exp: Math.floor(Date.now() / 1000) + 86400
  };
  try {
    const encodedHeader = base64UrlEncode(
      stringToArrayBuffer(JSON.stringify(header))
    );

    const encodedPayload = base64UrlEncode(
      stringToArrayBuffer(JSON.stringify(payload))
    );

    const dataToSign = stringToArrayBuffer(
      `${encodedHeader}.${encodedPayload}`
    );

    const signature = await crypto.sign(
      { name: "RSASSA-PKCS1-v1_5" },
      privateKey,
      dataToSign
    );

    const token = `${encodedHeader}.${encodedPayload}.${base64UrlEncode(
      signature
    )}`;

    return token;
  } catch (error) {
    console.error("Signing error:", error);
  }
};

export const decodeJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const payload = JSON.parse(window.atob(base64));
  return payload;
};

export const getToken = (token) => {
  return cookie.get("dropla_auth");
};
export const setToken = (token, unixTimestamp) => {
  const currentDate = new Date();
  const targetDate = new Date(unixTimestamp * 1000);
  const differenceInMilliseconds = targetDate - currentDate;
  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  cookie.set("dropla_auth", token, {
    expires: differenceInDays
  });

  cookie.set("dropla_auth", token, {
    expires: differenceInDays,
    domain: ".vision.dropla.tech",
    secure: true,
    sameSite: "Strict"
  });
};
export const deleteAuthData = () => {
  cookie.remove("dropla_auth", {});
  cookie.remove("dropla_auth", {
    domain: ".vision.dropla.tech",
    secure: true,
    sameSite: "Strict"
  });
};
