import L from "leaflet";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef
} from "react";
import { useDispatch } from "react-redux";

import { MapModesEnum } from "constant";
import features from "features";

import IconButton from "components/buttons/IconButton/IconButton";

import CursorArrowIcon from "assets/icons/cursor-arrow.svg";
import PenToolIcon from "assets/icons/pen-tool.svg";
import PlusIcon from "assets/icons/plus-black.svg";

import styles from "./map-toolbar.module.scss";

interface MapToolbarInterface {
  onChangeMode?: Dispatch<SetStateAction<MapModesEnum>>;
}

const MapToolbar = ({ onChangeMode }: MapToolbarInterface) => {
  const dispatch = useDispatch();
  const toolbarRef = useRef(null);

  useEffect(() => {
    if (toolbarRef.current) {
      L.DomEvent.disableClickPropagation(toolbarRef.current);
      L.DomEvent.disableScrollPropagation(toolbarRef.current);
    }
  }, []);

  const handleToolClick = useCallback(
    (mode: MapModesEnum) => () => {
      if (mode === MapModesEnum.DEFAULT) {
        dispatch(features.unitsManager.actions.clearEditPolygonParameters());
      }
      onChangeMode(mode);
    },
    [dispatch, onChangeMode]
  );

  return (
    <div className={styles["map-toolbar"]} ref={toolbarRef}>
      <IconButton
        icon={CursorArrowIcon}
        className={styles["icon-button"]}
        onClick={handleToolClick(MapModesEnum.DEFAULT)}
      />
      <IconButton
        icon={PenToolIcon}
        className={styles["icon-button"]}
        onClick={handleToolClick(MapModesEnum.POLYGON_CREATION)}
      />
      <div className={styles["separator"]} />
      <IconButton icon={PlusIcon} className={styles["icon-button"]} />
    </div>
  );
};

export default MapToolbar;
