import { DEFAULT_MANUAL_POINTS_LIST } from "constant";

import FormField from "components/inputs/FormField/FormField";
import FormSelect from "components/inputs/FormSelect/FormSelect";

import classNames from "classnames";
import styles from "./map-point-modal-form.module.scss";

interface MapPointModalFormInterface {
  setValue: any;
  values: any;
  isLock?: boolean;
}

const MapPointModalForm = ({
  setValue,
  values,
  isLock
}: MapPointModalFormInterface) => {
  return (
    <div className={styles["modal__form"]}>
      <FormField
        name={"name"}
        label="Name"
        placeholder="Name point"
        disabled={isLock}
      />
      <div className={styles["form__coordinates"]}>
        <FormField
          name={"coordinates.lat"}
          label="Latitude"
          disabled={isLock}
        />
        <FormField
          name={"coordinates.lng"}
          label="Longitude"
          disabled={isLock}
        />
      </div>
      <FormSelect
        name="object_type"
        label="Type object"
        options={DEFAULT_MANUAL_POINTS_LIST}
        value={values["object_type"]}
        onSelectChange={(e) => setValue("object_type", e)}
        withFormik
        isDisabled={isLock}
      />
      {/* TODO add additional props for explosive objects */}
      {/* {values.object_type?.value === PointTypes.EXO && (
            <>
              <FormSelect
                name="type_option"
                options={DEFAULT_MANUAL_POINTS_LIST}
                value={values["type"]}
                onSelectChange={(e) => setValue("type", e)}
                isDisabled={isLock}
              />
              <FormSelect
                name="type_option"
                options={DEFAULT_MANUAL_POINTS_LIST}
                value={values["type"]}
                onSelectChange={(e) => setValue("type", e)}
                isDisabled={isLock}  
              />
            </>
          )} */}
      <div className={styles["form__diameter"]}>
        <FormField
          name={"object_diameter"}
          label="Diameter"
          type="number"
          disabled={isLock}
        />
        <label
          className={classNames(styles["diameter__dimensions"], {
            [styles["silver-color"]]: isLock
          })}
        >
          mm
        </label>
      </div>
    </div>
  );
};

export default MapPointModalForm;
