import { useCallback, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { INFERENCE_STATUSES, InferenceStatus, LayerAlias } from "constant";
import features from "features";
import { RootStateInterface } from "reducer";
import { LayersManagerStateInterface } from "scenes/LayersManager/ducks";

import IconButton from "components/buttons/IconButton/IconButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import InferenceStatusComponent from "components/InferenceStatusComponent/InferenceStatusComponent";
import UploadFiles from "components/inputs/UploadFiles/UploadFiles";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import DragHandleDotsIcon from "assets/icons/drag-handle-dots.svg";
import TrashIcon from "assets/icons/trash.svg";

import InferenceAction from "components/InferenceAction/InferenceAction";
import styles from "./provider-file-manager-modal.module.scss";

export interface ProviderFilesManagerModalInterface {
  title?: string;
  description?: string;
  isDisableClose?: boolean;
  acceptButtonLabel?: string;
  acceptButtonAction?: () => void;
  isWithProgress?: boolean;
  id: string;
  alias: LayerAlias;
  initProviders: any;
  onHide: (updatedProviders: any) => void;
}

const ProviderFilesManagerModal = () => {
  const dispatch = useDispatch();

  const {
    title,
    description,
    isDisableClose,
    alias,
    id,
    initProviders,
    onHide
  } = useSelector<RootStateInterface, ProviderFilesManagerModalInterface>(
    (state) => state.modal.modalProps
  );

  const { providers } = useSelector<
    RootStateInterface,
    LayersManagerStateInterface
  >((state) => state.layersManager);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { destination, draggableId: providerId } = result;
    const index = destination.index;

    dispatch(
      features.layersManager.actions.reorderProviderRequest({
        params: { id, alias, providerId },
        fields: { index }
      })
    );
  };

  const onSubmit = useCallback(
    (e) => {
      const file = e.target.files[0];

      dispatch(
        features.layersManager.actions.createProviderRequest({
          params: { id, alias },
          fields: { file }
        })
      );
    },
    [alias, dispatch, id]
  );

  const onDelete = useCallback(
    (providerId) => () => {
      dispatch(
        features.layersManager.actions.deleteProviderRequest({
          params: { id, alias, providerId }
        })
      );
    },
    [alias, dispatch, id]
  );

  useEffect(() => {
    dispatch(
      features.layersManager.actions.setProviders({
        params: { alias, id },
        providers: initProviders
      })
    );
  }, [alias, dispatch, id, initProviders]);

  useEffect(() => {
    return () => {
      dispatch(features.layersManager.actions.clearProvidersState());
    };
  }, [dispatch]);

  const onClose = useCallback(() => {
    onHide(providers);
    dispatch(features.modal.actions.hideModal());
  }, [dispatch, onHide, providers]);

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
      onHide={onClose}
    >
      <div className={styles["modal"]}>
        <div className={styles["modal__text-container"]}>
          <h2 className={styles["modal__title"]}>{title}</h2>
          <h4 className={styles["modal__description"]}>
            {description ?? "Manage files"}
          </h4>
          <div className={styles["modal__providers-table"]}>
            <div className={styles["providers-table__head"]}>
              <div>Name</div>
              <div>Action</div>
              <div>Inference action</div>
              <div>Inference status</div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {providers?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style
                            }}
                            className={styles["providers-table__body"]}
                          >
                            <div className={styles["drag-dots"]}>
                              <img src={DragHandleDotsIcon} alt="drag-dots" />
                            </div>
                            <div className={styles["name"]}>
                              {item.data.name}
                            </div>
                            <IconButton
                              onClick={onDelete(item.id)}
                              icon={TrashIcon}
                              disabled={item.isDeleteProviderLoading}
                              isLoading={item.isDeleteProviderLoading}
                            />
                            {item?.inference?.status ? (
                              <InferenceAction
                                id={id}
                                providerId={item.id}
                                alias={alias}
                                status={
                                  INFERENCE_STATUSES[item?.inference?.status]
                                }
                                isLoading={item.isInferenceActionLoading}
                                disabled={item.isDeleteProviderLoading}
                              />
                            ) : (
                              <div className={styles["name"]}>
                                File upload error
                              </div>
                            )}

                            <InferenceStatusComponent
                              status={
                                INFERENCE_STATUSES[item?.inference?.status]
                              }
                              completionPercentage={
                                item?.inference?.status ===
                                InferenceStatus.PROCESSING
                                  ? item?.inference?.data?.completion_percentage
                                  : item?.inference?.fileUploadData
                                  ? item?.inference?.fileUploadData.progress
                                  : undefined
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={styles["modal__upload"]}>
              <label className={styles["label"]}>
                Please select a file to upload
              </label>
              <UploadFiles title="Upload zip" handleFileChange={onSubmit} />
            </div>
          </div>
        </div>
        <div className={styles["modal__controls-wrapper"]}>
          <PrimaryButton type="button" onClick={onClose} textSize={14}>
            Done
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ProviderFilesManagerModal;
