import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ProgressBar from "components/buttons/ProgressBar/ProgressBar";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import SmallLoader from "components/SmallLoader/SmallLoader";
import styles from "./progress-modal.module.scss";

interface ProgressModalInterface {
  title?: string;
  description?: string;
  isDisableClose?: boolean;
  modalCancelButton?: {
    label: string;
    action?: () => void;
    isDisable?: boolean;
  };
  modalActionButton?: {
    label: string;
    action?: () => void;
    isDisable?: boolean;
  };
  progressMax?: number;
  progressValue: number;
}

const ProgressModal = () => {
  const {
    title,
    description,
    isDisableClose,
    modalActionButton,
    modalCancelButton,
    progressMax,
    progressValue
  } = useSelector<RootStateInterface, ProgressModalInterface>(
    (state) => state.modal.modalProps
  );

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
    >
      <div className={styles["progress-modal"]}>
        <div className={styles["progress-modal__text-container"]}>
          <h2 className={styles["progress-modal__title"]}>{title}</h2>
          {description && (
            <h4 className={styles["progress-modal__description"]}>
              <SmallLoader />
              {description}
            </h4>
          )}
        </div>
        <ProgressBar value={progressValue ?? 0} max={progressMax ?? 100} />
        <div>
          {modalCancelButton && (
            <CommonButton
              type="button"
              onClick={modalActionButton.action}
              className={styles["progress-modal__accept-button"]}
            >
              {modalActionButton.label ?? "Accept"}
            </CommonButton>
          )}
          {modalActionButton && (
            <PrimaryButton
              type="button"
              onClick={modalActionButton.action}
              textSize={14}
              className={styles["progress-modal__accept-button"]}
              isDisable={modalActionButton.isDisable}
            >
              {modalActionButton.label ?? "Accept"}
            </PrimaryButton>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ProgressModal;
