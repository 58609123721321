import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import features from "features";
import { decodeJwt, getToken } from "helpers/jwt";

const MAX_TIMEOUT = 2147483647;

const ProtectedRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const [authorisedJWT, setAuthorisedJWT] = useState(getToken());

  const timeUntilExpiration = useMemo(() => {
    if (authorisedJWT) {
      const decodedToken = decodeJwt(authorisedJWT);
      const currentTime = Date.now();

      return decodedToken.exp * 1000 - currentTime;
    } else return -1;
  }, [authorisedJWT]);

  const navigateToAuth = useCallback(async () => {
    setAuthorisedJWT(undefined);
    dispatch(features.auth.actions.signOutRequest());
  }, [dispatch]);

  // const authenticateClient = useCallback(async () => {
  //   const clientToken = await createClientToken();
  //   dispatch(
  //     features.auth.actions.initTokenRequest({
  //       params: { clientToken },
  //       onSuccess: (res) => {
  //         setAuthorisedJWT(res.message);
  //       }
  //     })
  //   );
  // }, [dispatch]);

  // useEffect(() => {
  //   if (timeUntilExpiration > 0) {
  //     setTimeout(() => {
  //       authenticateClient();
  //     }, timeUntilExpiration);
  //   } else {
  //     authenticateClient();
  //   }
  // }, [authenticateClient, timeUntilExpiration]);

  useEffect(() => {
    if (timeUntilExpiration > 0 && timeUntilExpiration < MAX_TIMEOUT) {
      setTimeout(() => {
        navigateToAuth();
      }, timeUntilExpiration);
    } else if (timeUntilExpiration > MAX_TIMEOUT) {
    } else {
      navigateToAuth();
    }
  }, [navigateToAuth, timeUntilExpiration]);

  useEffect(() => {
    if (authorisedJWT) {
      const decodedToken = decodeJwt(authorisedJWT);
      dispatch(features.auth.actions.setTokenData(decodedToken));
    }
  }, [authorisedJWT, dispatch]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
