import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./accordion.module.scss";

interface HeaderInterface {
  isOpen: boolean;
  onToggleAccordion: () => void;
}

interface AccordionInterface {
  className?: string;
  header: ({ isOpen, onToggleAccordion }: HeaderInterface) => JSX.Element;
  children: JSX.Element;
  isOpenDefault?: boolean;
  noScroll?: boolean;
}

const Accordion = ({
  className,
  header,
  children,
  isOpenDefault = false,
  noScroll = false
}: AccordionInterface) => {
  const contentRef = useRef(null);

  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const [maxHeight, setMaxHeight] = useState("0px");
  const [isScrollable, setIsScrollable] = useState(false);

  const onToggleAccordion = useCallback(() => {
    setIsOpen((prevVal) => !prevVal);
  }, []);

  const updateMaxHeight = useCallback(() => {
    const content = contentRef.current;
    if (content) {
      const bottomPadding = 20;
      const pageViewHeight =
        window.innerHeight -
        content.getBoundingClientRect().top -
        bottomPadding;
      const contentHeight = content.scrollHeight;

      if (!noScroll && contentHeight > pageViewHeight) {
        setMaxHeight(`${pageViewHeight}px`);
        setIsScrollable(true);
      } else {
        setMaxHeight(`${contentHeight}px`);
        setIsScrollable(false);
      }
    }
  }, [noScroll]);

  useEffect(() => {
    updateMaxHeight();

    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [updateMaxHeight, children]);

  useEffect(() => {
    const content = contentRef.current;
    const resizeObserver = new ResizeObserver(() => {
      updateMaxHeight();
    });

    if (content) {
      resizeObserver.observe(content);
    }

    return () => {
      if (content) {
        resizeObserver.unobserve(content);
      }
    };
  }, [isOpen, children, updateMaxHeight]);

  useEffect(() => {
    if (isOpen) {
      updateMaxHeight();
    }
  }, [isOpen, children, updateMaxHeight]);

  const handleInnerResize = useCallback(() => {
    updateMaxHeight();
  }, [updateMaxHeight]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles["wrapper__header"]}>
        {header({ isOpen, onToggleAccordion })}
      </div>
      <div
        className={classNames(styles["wrapper__body"], {
          [styles["wrapper__body--scroll"]]: isScrollable
        })}
        style={{ maxHeight: isOpen ? maxHeight : "0px" }}
        ref={contentRef}
        onTransitionEnd={handleInnerResize}
      >
        <div className={styles["wrapper__content"]}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
