import { FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import features from "features";
import { RootStateInterface } from "reducer";
import { AuthStateInterface } from "scenes/AuthPage/ducks";
import { TeamAndStaffInterface } from "../ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import FormField from "components/inputs/FormField/FormField";

import SearchIcon from "assets/icons/magnifying-glass.svg";
import EditIcon from "assets/icons/pencil.svg";
import PlusIcon from "assets/icons/plus.svg";
import AddDeputyIcon from "assets/icons/user-plus.svg";

import TeamsList from "components/TeamsList/TeamsList";

import { UserRoles } from "constant";
import useTeamEmployeeHandler from "hooks/useTeamEmployeeHandler";
import styles from "./team-and-staff-page.module.scss";

const TeamAndStaffPage = () => {
  const dispatch = useDispatch();

  const { isCreateTeamLoading, deputies } = useSelector<
    RootStateInterface,
    TeamAndStaffInterface
  >((state) => state.teamAndStaff);

  const { jwtData } = useSelector<RootStateInterface, AuthStateInterface>(
    (state) => state.auth
  );

  const { createTeamEmployee, editTeamEmployee } = useTeamEmployeeHandler({
    orgId: jwtData?.org_id,
    id: jwtData?.dep_id
  });

  const formik = useFormik({
    initialValues: { search: "" },
    onSubmit: () => {}
  });

  const onSubmitAddTeam = useCallback(
    (val: { name: string }) => {
      const { org_id, dep_id } = jwtData;
      const { name } = val;
      dispatch(
        features.teamAndStaff.actions.createTeamRequest({
          params: { org_id },
          fields: { name, parent_id: dep_id }
        })
      );
      dispatch(features.modal.actions.hideModal());
    },
    [dispatch, jwtData]
  );

  const onAddTeam = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "FORM",
        modalProps: {
          title: "Create Team",
          description: "Please enter new name.",
          initialValues: { name: "" },
          onSubmit: onSubmitAddTeam,
          validationSchema: Yup.object().shape({
            name: Yup.string().required("Team name is required")
          }),
          acceptButtonLabel: "Send",
          formContent: (
            <>
              <FormField name="name" placeholder="Team name" />
            </>
          )
        }
      })
    );
  }, [dispatch, onSubmitAddTeam]);

  const onAddDeputy = useCallback(() => {
    createTeamEmployee(UserRoles.HEAD);
  }, [createTeamEmployee]);
  const onEditDeputy = useCallback(
    (user) => () => {
      editTeamEmployee(user);
    },
    [editTeamEmployee]
  );

  const isProcessing = useMemo(
    () => isCreateTeamLoading,
    [isCreateTeamLoading]
  );

  useEffect(() => {
    if (jwtData) {
      const { org_id, dep_id } = jwtData;

      dispatch(
        features.teamAndStaff.actions.fetchDeputiesRequest({
          params: { org_id, dep_id }
        })
      );
    }
  }, [dispatch, jwtData]);

  useEffect(() => {
    if (isProcessing)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Processing",
            loading: true,
            isDisableClose: true
          }
        })
      );
    else dispatch(features.modal.actions.hideModal());
  }, [dispatch, isProcessing]);

  return (
    <div className={styles["team-and-staff-page"]}>
      <div className={styles["team-and-staff-page__header"]}>
        <div className={styles["team-and-staff-page__title"]}>
          Team and staff
        </div>
        <div className={styles["team-and-staff-page__description"]}>
          All created departments will be collected here.
        </div>
      </div>
      <div className={styles["team-and-staff-page__top"]}>
        <FormikProvider value={formik}>
          <form className={styles["search-form"]}>
            <FormField
              icon={SearchIcon}
              name="search"
              placeholder="Serch department or staff"
            />
          </form>
        </FormikProvider>
        <div className={styles["team-and-staff-page__actions"]}>
          <CommonButton onClick={onAddDeputy} icon={AddDeputyIcon}>
            Add Deputy
          </CommonButton>
          <PrimaryButton onClick={onAddTeam} icon={PlusIcon}>
            Add team
          </PrimaryButton>
        </div>
      </div>
      <div className={styles["team-and-staff-page__content"]}>
        {deputies.length > 0 && (
          <div className={styles["deputies"]}>
            <h1 className={styles["deputies__tite"]}>Deputies</h1>
            <div className={styles["deputies__list"]}>
              {deputies.map((item) => (
                <div className={styles["deputy"]} key={`deputy-${item.id}`}>
                  <div className={styles["deputy__user"]}>
                    {item.name} {item.surname}
                  </div>
                  <div className={styles["deputy__role"]}>{item.role.name}</div>
                  <CommonButton icon={EditIcon} onClick={onEditDeputy(item)}>
                    Edit
                  </CommonButton>
                </div>
              ))}
            </div>
          </div>
        )}
        <TeamsList />
      </div>
    </div>
  );
};

export default TeamAndStaffPage;
