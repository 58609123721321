import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { RootStateInterface } from "reducer";

import { AuthStateInterface } from "scenes/AuthPage/ducks";
import Pagination from "scenes/MainPage/components/Pagination/Pagination";
import { TeamAndStaffInterface } from "scenes/TeamAndStaffPage/ducks";

import Alert from "components/Alert/Alert";
import Preloader from "components/Preloader/Preloader";
import Team from "components/Team/Team";

import styles from "./teams-list.module.scss";

const PAGINATION_STEP = 6;

const TeamsList = () => {
  const { teams, teamsTotal, isLoading } = useSelector<
    RootStateInterface,
    TeamAndStaffInterface
  >((state) => state.teamAndStaff);
  const { jwtData } = useSelector<RootStateInterface, AuthStateInterface>(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [teamsPrevTotal, setTeamsPrevTotal] = useState(teamsTotal);

  const pageCount = useMemo(() => {
    return Math.ceil(teamsTotal / PAGINATION_STEP);
  }, [teamsTotal]);

  const skipCount = useMemo(() => {
    return PAGINATION_STEP * currentPage;
  }, [currentPage]);

  const isFirstOrPrevPage = useMemo(() => {
    return currentPage > 0;
  }, [currentPage]);

  const isNextOrLastPage = useMemo(() => {
    return currentPage + 1 < pageCount;
  }, [currentPage, pageCount]);

  const onFirstPage = useCallback(() => {
    if (isFirstOrPrevPage) {
      setCurrentPage(0);
    }
  }, [isFirstOrPrevPage]);

  const onPrevPage = useCallback(() => {
    if (isFirstOrPrevPage) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, isFirstOrPrevPage]);

  const onNextPage = useCallback(() => {
    if (isNextOrLastPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, isNextOrLastPage]);

  const onLastPage = useCallback(() => {
    if (isNextOrLastPage) {
      setCurrentPage(pageCount - 1);
    }
  }, [isNextOrLastPage, pageCount]);

  const updateTeamsList = useCallback(() => {
    if (jwtData) {
      const { org_id, dep_id } = jwtData;

      dispatch(
        features.teamAndStaff.actions.fetchTeamsRequest({
          params: { org_id, dep_id, limit: PAGINATION_STEP, skip: skipCount }
        })
      );
    }
  }, [jwtData, dispatch, skipCount]);

  useEffect(() => {
    if (teamsTotal > teamsPrevTotal) {
    } else if (teamsTotal < teamsPrevTotal) {
      if (teams.length === 1) {
        setCurrentPage((prev) => (prev > 0 ? prev - 1 : prev));
      }
    }
    setTeamsPrevTotal(teamsTotal);
  }, [teamsTotal, teamsPrevTotal, teams.length]);

  useEffect(() => {
    updateTeamsList();
  }, [updateTeamsList, teamsTotal]);

  return (
    <div className={styles["wrapper"]}>
      {!isLoading ? (
        teams.length > 0 ? (
          <div className={styles["wrapper__body"]}>
            <div className={styles["wrapper__teams"]}>
              {teams?.map((item, i) => (
                <Team
                  {...item}
                  key={`team-${i}-${item.name}`}
                  orgId={jwtData.org_id}
                  // updateTeamsList={handleUpdateTeamsList}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onFirstPage={onFirstPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              onLastPage={onLastPage}
            />
          </div>
        ) : (
          <Alert
            title={"No department available yet. "}
            description={"Please create a new department."}
            maxWidth={512}
          />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};
export default TeamsList;
