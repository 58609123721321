import { LayerAlias } from "constant";
import { MapProvider } from "../ApiServiceProviders";

export const getUnits = async ({ params }) => {
  return MapProvider.client
    .get(
      `/projects/${params.projectId}/layers/${params.alias}/units?limit=${
        params.limit ?? 99999999
      }&skip=${params.skip ?? 0}&type=${params.type}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const getUnit = async ({ params }) => {
  return MapProvider.client
    .get(
      `/projects/${params.projectId}/layers/${params.alias}/units/${params.id}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const addUnit = async ({ params, fields }) => {
  return MapProvider.client
    .post(
      `/projects/${params.projectId}/layers/${LayerAlias.MANUAL}/units`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const putUnit = async ({ params, fields }) => {
  return MapProvider.client
    .put(
      `/projects/${params.projectId}/layers/${params.alias}/units/${params.unitId}`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const removeUnit = async ({ params }) => {
  return MapProvider.client
    .delete(
      `/projects/${params.projectId}/layers/${params.alias}/units/${params.unitId}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};
