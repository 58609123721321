import { call, put, take } from "redux-saga/effects";
import { eventChannel, END, buffers } from "redux-saga";
import axios from "axios";

import { env } from "../env";
import { setRequestError } from "scenes/ErrorPage/saga";

function* requestMiddleware({
  request,
  params,
  fields,
  setSuccess,
  setError,
  onSuccess,
  onError,
  isReturnRequestPayload
}) {
  try {
    const data = yield call(request, { params, fields });

    if (data && data.status >= 200 && data.status < 300) {
      if (setSuccess)
        isReturnRequestPayload
          ? yield put(setSuccess({ data: data.data, params, fields }))
          : yield put(setSuccess(data.data));

      if (onSuccess)
        isReturnRequestPayload
          ? yield onSuccess({ data: data.data, params, fields })
          : yield onSuccess(data.data);
    } else {
      if (setError)
        isReturnRequestPayload
          ? yield put(setError({ data: data?.data, params, fields }))
          : yield put(setError(data?.data));

      if (onError)
        isReturnRequestPayload
          ? yield onError({ data: data?.data, params, fields })
          : yield onError(data?.data);

      yield call(setRequestError, data);
    }
  } catch (err) {
    if (env.NODE_ENV === "development") {
      console.log(err);
    }
    yield put(setError());
    yield call(setRequestError, err.response);
    if (onError) yield onError();
  }
}

function createUploadChannel(request, { params, fields }) {
  return eventChannel((emitter) => {
    const config = {
      cancelToken: new axios.CancelToken(function executor(cancelExecutor) {
        emitter({ cancelExecutor });
      }),
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        emitter({ progress });
      }
    };

    // Config is required in api call
    request({ params, fields, config })
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300)
          emitter({ successRes: response.data });
        else emitter({ errorRes: response });

        emitter(END);
      })
      .catch((error) => {
        emitter({ errorRes: error });
        emitter(END);
      });

    return () => {};
  }, buffers.sliding(2));
}

/**
 * Don`t forget add config to api call
 */
function* uploadFileMiddleware({
  request,
  params,
  fields,
  setSuccess,
  setError,
  setUploadProgress,
  onSuccess,
  onError,
  getUploadCancelExecutor,
  isReturnRequestPayload
}) {
  const uploadChannel = yield call(createUploadChannel, request, {
    params,
    fields
  });

  while (true) {
    const { progress, cancelExecutor, successRes, errorRes } = yield take(
      uploadChannel
    );

    if (cancelExecutor && getUploadCancelExecutor) {
      yield put(
        getUploadCancelExecutor({
          cancelExecutor,
          params
        })
      );
    }

    if (successRes) {
      if (setSuccess)
        isReturnRequestPayload
          ? yield put(setSuccess({ data: successRes, params, fields }))
          : yield put(setSuccess(successRes));

      if (onSuccess)
        isReturnRequestPayload
          ? yield onSuccess({ data: successRes, params, fields })
          : yield onSuccess(successRes);

      break;
    } else if (errorRes) {
      yield put(setError(errorRes?.data));
      yield call(setRequestError, errorRes);

      break;
    } else {
      yield put(setUploadProgress({ progress, params }));
    }
  }
}

export { requestMiddleware, uploadFileMiddleware };
