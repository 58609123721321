import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { toastr } from "react-redux-toastr";

import CommonButton from "components/buttons/CommonButton/CommonButton";

import UploadIcon from "assets/icons/upload.svg";

import styles from "./upload-files.module.scss";

interface UploadFilesInterface {
  handleFileChange: (event: any) => void;
  isMultiple?: boolean;
  title?: string;
  className?: string;
  isDrop?: boolean;
  accept?: string;
}
const UploadFiles = ({
  handleFileChange,
  isMultiple,
  title,
  className,
  isDrop,
  accept
}: UploadFilesInterface) => {
  const ref = useRef<HTMLInputElement>();

  const [isDragEnter, setIsDragEnter] = useState(false);
  const [dropFilename, setDropFilename] = useState("");

  const onChooseFile = useCallback(() => {
    ref.current.click();
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];

      if (`.${file.name.split(".").pop().toLowerCase()}` !== accept)
        toastr.error(`Error`, `Please select allowed file in format ${accept}`);
      else {
        handleFileChange({
          ...e,
          target: { ...e.target, files: e.dataTransfer.files }
        });
        setDropFilename(e.dataTransfer.files[0].name);
      }
    }
    setIsDragEnter(false);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragEnter(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragEnter(true);
  };

  const onFileChange = useCallback(
    (e) => {
      if (isDrop && e.target?.files[0]?.name)
        setDropFilename(e.target.files[0].name);
      handleFileChange(e);
    },
    [handleFileChange, isDrop]
  );

  useEffect(() => {
    const handlePaste = async (e) => {
      const item = Array.from(e.clipboardData.items).find((x) =>
        /^image\//.test((x as DataTransferItem).type)
      ) as DataTransferItem;
      if (item) {
        const blob = item.getAsFile();
        const file = new File([blob], "pasted-image", {
          type: blob.type
        });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        if (ref.current) {
          ref.current.files = dataTransfer.files;
          const event = new Event("change", { bubbles: true });
          ref.current.dispatchEvent(event);
        }
      }
    };

    window.addEventListener("paste", handlePaste);

    return () => {
      window.removeEventListener("paste", handlePaste);
    };
  }, []);

  return (
    <div className={className}>
      {isDrop ? (
        <div
          className={classNames(styles["drop-area"], {
            [styles["drop-area--over"]]: isDragEnter,
            [styles["drop-area--selected"]]: dropFilename.length > 0
          })}
          onClick={onChooseFile}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
        >
          {dropFilename.length > 0 ? (
            <>
              Key: {dropFilename}
              <br />
              <span>
                If you want to reload, click on this field or drag the file here
              </span>
            </>
          ) : (
            <>
              {title}
              <br />
              <span>Supported formats: .key</span>
            </>
          )}
          <div className={styles["drop-area__upload"]}>
            <img src={UploadIcon} alt="upload-icon" />
          </div>
        </div>
      ) : (
        <CommonButton type="button" icon={UploadIcon} onClick={onChooseFile}>
          {title ?? "Upload"}
        </CommonButton>
      )}
      <input
        className={styles["upload__input"]}
        type="file"
        id="files"
        aria-label="files"
        onChange={onFileChange}
        multiple={isMultiple}
        ref={ref}
        accept={accept}
      />
    </div>
  );
};

export default UploadFiles;
