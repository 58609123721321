import { InferenceProvider, MapProvider } from "services/ApiServiceProviders";

export const addLayerProvider = ({ params, fields }) => {
  return MapProvider.client
    .post(`/projects/${params.id}/layers/${params.alias}/providers`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const removeLayerProvider = ({ params }) => {
  return MapProvider.client
    .delete(
      `/projects/${params.id}/layers/${params.alias}/providers/${params.providerId}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const reorderLayerProvider = ({ params, fields }) => {
  return MapProvider.client
    .put(
      `/projects/${params.id}/layers/${params.alias}/providers/${params.providerId}/reorder`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const getProviderInferenceStatus = ({ params }) => {
  return InferenceProvider.client
    .get(
      `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}/status`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const prepareProviderInference = ({ params, fields, config }) => {
  if (config) {
    return InferenceProvider.clientUpload
      .post(
        `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}/prepare`,
        fields,
        config
      )
      .then((response) => response)
      .catch((error) => error.response);
  }

  return InferenceProvider.client
    .post(
      `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}/prepare`,
      fields
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const removeProviderInference = ({ params }) => {
  return InferenceProvider.client
    .delete(
      `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const runProviderInference = ({ params }) => {
  return InferenceProvider.client
    .post(
      `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}/run`
    )
    .then((response) => response)
    .catch((error) => error.response);
};

export const stopProviderInference = ({ params }) => {
  return InferenceProvider.client
    .post(
      `/projects/${params.id}/inference/${params.alias}/providers/${params.providerId}/stop`
    )
    .then((response) => response)
    .catch((error) => error.response);
};
