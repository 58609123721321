import cn from "classnames";
import React from "react";

import SmallLoader from "components/SmallLoader/SmallLoader";
import styles from "./icon-button.module.scss";

interface IconButtonInterface {
  children?: any | undefined;
  icon: any;
  className?: string;
  classNameIcon?: string;
  alt?: string;
  disabled?: Boolean;
  type?: "button" | "submit" | "reset";
  onClick?: (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => void;
  isLoading?: boolean;
}

const IconButton = ({
  children = undefined,
  icon,
  type,
  className = "",
  classNameIcon = "",
  alt = "",
  disabled,
  onClick,
  isLoading
}: IconButtonInterface): JSX.Element => {
  return (
    <button
      className={cn(styles["icon-button"], className, {
        [styles["icon-button--disabled"]]: disabled
      })}
      onClick={!disabled ? onClick : () => {}}
      type={type || "button"}
    >
      {isLoading ? (
        <SmallLoader />
      ) : typeof icon === "string" ? (
        <img
          src={icon}
          alt={alt}
          className={cn(styles["icon-button__icon"], classNameIcon)}
        />
      ) : (
        icon
      )}

      {children}
    </button>
  );
};

export default IconButton;
