import L from "leaflet";
import { useEffect, useRef, useCallback } from "react";

import IconButton from "components/buttons/IconButton/IconButton";

import CloseIcon from "assets/icons/close-small.svg";
import CheckIcon from "assets/icons/check.svg";

import styles from "./map-confirm-controls.module.scss";

interface MapConfirmControlsInterface {
  onAccept?: () => void;
  onDecline?: () => void;
}

const MapConfirmControls = ({
  onAccept,
  onDecline
}: MapConfirmControlsInterface) => {
  const confirmControlsRef = useRef(null);

  useEffect(() => {
    if (confirmControlsRef.current) {
      L.DomEvent.disableClickPropagation(confirmControlsRef.current);
      L.DomEvent.disableScrollPropagation(confirmControlsRef.current);
    }
  }, []);

  const handleAcceptClick = useCallback(() => {
    onAccept();
  }, [onAccept]);

  const handleDeclineClick = useCallback(() => {
    onDecline();
  }, [onDecline]);

  return (
    <div className={styles["map-confirm-controls"]} ref={confirmControlsRef}>
      <IconButton
        icon={CheckIcon}
        className={styles["icon-button"]}
        onClick={handleAcceptClick}
      />
      <IconButton
        icon={CloseIcon}
        className={styles["icon-button"]}
        onClick={handleDeclineClick}
      />
    </div>
  );
};

export default MapConfirmControls;
