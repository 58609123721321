import cn from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { RootStateInterface } from "reducer";
import { MainStateInterface } from "scenes/MainPage/ducks";

import Alert from "components/Alert/Alert";
import NewProjectButton from "components/buttons/NewProjectButton/NewProjectButton";
import Preloader from "components/Preloader/Preloader";
import Pagination from "../Pagination/Pagination";
import ProjectPreview from "../ProjectPreview/ProjectPreview";
import Select from "../Select/Select";

import styles from "./projects-list.module.scss";

const PAGINATION_STEP = 10;

const ProjectsList = () => {
  const { projects, projectsTotal, isLoading } = useSelector<
    RootStateInterface,
    MainStateInterface
  >((state) => state.main);

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = useMemo(() => {
    return Math.ceil(projectsTotal / PAGINATION_STEP);
  }, [projectsTotal]);

  const skipCount = useMemo(() => {
    return PAGINATION_STEP * currentPage;
  }, [currentPage]);

  const isFirstOrPrevPage = useMemo(() => {
    return currentPage > 0;
  }, [currentPage]);

  const isNextOrLastPage = useMemo(() => {
    return currentPage + 1 < pageCount;
  }, [currentPage, pageCount]);

  const onFirstPage = useCallback(() => {
    if (isFirstOrPrevPage) {
      setCurrentPage(0);
    }
  }, [isFirstOrPrevPage]);

  const onPrevPage = useCallback(() => {
    if (isFirstOrPrevPage) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, isFirstOrPrevPage]);

  const onNextPage = useCallback(() => {
    if (isNextOrLastPage) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, isNextOrLastPage]);

  const onLastPage = useCallback(() => {
    if (isNextOrLastPage) {
      setCurrentPage(pageCount - 1);
    }
  }, [isNextOrLastPage, pageCount]);

  const updateProjectsList = useCallback(
    () =>
      dispatch(
        features.main.actions.fetchProjectsRequest({
          params: {
            limit: PAGINATION_STEP,
            skip: skipCount
          }
        })
      ),
    [skipCount, dispatch]
  );

  useEffect(() => {
    updateProjectsList();
  }, [dispatch, skipCount, updateProjectsList]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__head"]}>
        <div className={styles["title"]}>Project</div>
        <Select options={[{ label: "Recently" }]} />
        <NewProjectButton />
      </div>
      {!isLoading ? (
        projects.length > 0 ? (
          <div className={styles["wrapper__body"]}>
            <div className={styles["wrapper__projects"]}>
              <div className={styles["projects-table__header"]}>
                <h4
                  className={cn(
                    styles["projects-table__header-label"],
                    styles["projects-table__header-name"]
                  )}
                >
                  Name project
                </h4>
                <h4
                  className={cn(
                    styles["projects-table__header-label"],
                    styles["projects-table__header-date"]
                  )}
                >
                  Date
                </h4>
                <h4
                  className={cn(
                    styles["projects-table__header-label"],
                    styles["projects-table__header-action"]
                  )}
                >
                  Action
                </h4>
              </div>
              {projects?.map((item, i) => (
                <ProjectPreview
                  {...item}
                  key={`project-${i}`}
                  updateProjectsList={updateProjectsList}
                />
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              pageCount={pageCount}
              onFirstPage={onFirstPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              onLastPage={onLastPage}
            />
          </div>
        ) : (
          <Alert
            title="No projects available yet."
            description="Please create a new project."
          />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};
export default ProjectsList;
