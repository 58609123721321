import useAvailableTools from "hooks/useAvailableTools";

import MainUtil from "components/MainUtil/MainUtil";

import styles from "./utils-list.module.scss";

const UtilsList = () => {
  const availableTools = useAvailableTools();

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["wrapper__head"]}>
        <div className={styles["title"]}>Tools</div>
      </div>
      <div className={styles["wrapper__body"]}>
        {availableTools && (
          <div className={styles["utils-list"]}>
            {availableTools.map((item, index) => (
              <MainUtil {...item} key={`util-${index}`} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default UtilsList;
