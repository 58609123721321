import { useCallback, useState } from "react";

import FormField from "components/inputs/FormField/FormField";

import AlertIcon from "assets/icons/Alert.svg";
import CopyIcon from "assets/icons/copy.svg";
import FilekeyIcon from "assets/icons/file-key.svg";
import ReloadIcon from "assets/icons/reload.svg";
import DeleteIcon from "assets/icons/trash-2.svg";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import styles from "./team-employee-form.module.scss";

const TEXT_RELOAD_DELAY = 5000;

interface TeamEmployeeFormInterface {
  setValue?: any;
  values?: any;
  isLock?: boolean;
  credentials?: {
    key?: string;
    password?: string;
  };
  onRegenerateCredentials?: any;
  onDelete?: any;
}

const TeamEmployeeForm = ({
  isLock,
  onRegenerateCredentials,
  credentials,
  onDelete
}: TeamEmployeeFormInterface) => {
  const [isPasswordCopied, setIsPasswordCopied] = useState(false);

  const onCopyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(credentials.password)
      .then(() => {
        setIsPasswordCopied(true);
      })
      .catch(() => {
        setIsPasswordCopied(false);
      });

    setTimeout(() => {
      setIsPasswordCopied(false);
    }, TEXT_RELOAD_DELAY);
  }, [credentials]);

  const downloadStringAsFile = useCallback(
    (content) => () => {
      // const blob = new Blob([content], { type: contentType });
      const blob = new Blob([content]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "private.key";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    []
  );

  return (
    <div className={styles["employee-form"]}>
      <h4 className={styles["employee-form__title"]}>Name</h4>
      <FormField name="name" placeholder="First Name" disabled={isLock} />
      <FormField name="surname" placeholder="Last Name" disabled={isLock} />

      {onRegenerateCredentials && !credentials && (
        <>
          <CommonButton onClick={onRegenerateCredentials} icon={ReloadIcon}>
            Regenerate key & password
          </CommonButton>
        </>
      )}

      {credentials && (
        <>
          <h4 className={styles["employee-form__title"]}>Key</h4>
          <CommonButton
            onClick={downloadStringAsFile(credentials.key)}
            icon={FilekeyIcon}
          >
            private.key
          </CommonButton>

          <h4 className={styles["employee-form__title"]}>Password</h4>

          {!isPasswordCopied ? (
            <div onClick={onCopyToClipboard} className={styles["password"]}>
              <img src={CopyIcon} className={styles["copy"]} alt="copy-icon" />
              <p>{credentials.password}</p>
            </div>
          ) : (
            <div className={styles["password__copied"]}>Password copied!</div>
          )}

          <div className={styles["warning"]}>
            <img src={AlertIcon} alt="alert-icon" /> After closing this window,
            the key and password will not be available for copying
          </div>
        </>
      )}
      {onDelete && (
        <>
          <h4 className={styles["employee-form__title"]}>Manage</h4>
          <CommonButton icon={DeleteIcon} onClick={onDelete}>
            Delete user
          </CommonButton>
        </>
      )}
    </div>
  );
};

export default TeamEmployeeForm;
