import { InferenceProvider, MapProvider } from "../ApiServiceProviders";

export const getWikiDetails = async ({ params }) => {
  return MapProvider.client
    .get(`https://wiki.dev.vision.dropla.tech/mines?ids=${params.id}`)
    .then((response) => response)
    .catch((error) => error.response);
};





export const runInference = ({ params, fields }) => {
  return InferenceProvider.client
    .post(`/projects/${params.id}/inference/${params.alias}`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};

export const stopInference = ({ params, fields }) => {
  return InferenceProvider.client
    .post(`/projects/${params.id}/inference/${params.alias}/stop`, fields)
    .then((response) => response)
    .catch((error) => error.response);
};
