import * as turf from "@turf/turf";
import cn from "classnames";
import L, { LatLngTuple } from "leaflet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Polygon, TileLayer, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";

import { ProviderManagerModalInterface } from "components/modals/ProviderManagerModal/ProviderManagerModal";
import {
  GEO_VISION_API_URL,
  KYIV_COORDINATES,
  LayerAlias,
  MapModesEnum,
  STORAGE_URL,
  UNITS_LIMIT,
  UnitTypes
} from "constant";
import features from "features";
import { convertPercentagesToNumber } from "helpers/convertPercentagesToNumber";
import { ensureClosedPolygon } from "helpers/ensureClosedPolygon";
import { getToken } from "helpers/jwt";
import { RootStateInterface } from "reducer";
import { LayersManagerStateInterface } from "scenes/LayersManager/ducks";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";
import { MapStateInterface } from "../ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import DropdownMenu from "components/DropdownMenu/DropdownMenu";
import ZoomBtns from "components/ZoomBtns/ZoomBtns";
import MapConfirmControls from "../components/MapConfirmControls/MapConfirmControls";
import MapCreatePolygonModalForm from "../components/MapCreatePolygonModalForm/MapCreatePolygonModalForm";
import MapLayers from "../components/MapLayers/MapLayers";
import MapMarkers from "../components/MapMarkers/MapMarkers";
import MapPolygonBuilder from "../components/MapPolygonBuilder/MapPolygonBuilder";
import MapPolygons from "../components/MapPolygons/MapPolygons";
import MapProjectName from "../components/MapProjectName/MapProjectName";
import MapSearchPoint from "../components/MapSearchPoint/MapSearchPoint";
import MapToolbar from "../components/MapToolbar/MapToolbar";

import DeleteIcon from "assets/icons/trash-2.svg";
import TrashIcon from "assets/icons/trash.svg";

import MapMarkerDetails from "../components/MapMarkerDetails/MapMarkerDetails";
import styles from "./map-page.module.scss";

const UNITS_SKIP_COUNT = 0;

const MapPage = () => {
  const mapRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [perimeterBounds, setPerimeterBounds] = useState<LatLngTuple[] | null>(
    null
  );
  const [mapMode, setMapMode] = useState<MapModesEnum>(MapModesEnum.DEFAULT);
  const [contextMenu, setContextMenu] = useState({
    position: null,
    visible: false,
    options: null
  });

  const {
    title,
    aries,
    // markers,
    markerDetails,
    isProjectLoading,
    isMarkerDetailsLoading,
    isMarkerDeleteLoading,
    isLayerCreateLoading,
    isMarkersLoading,
    isMarkerCreateLoading
  } = useSelector<RootStateInterface, MapStateInterface>((state) => state.map);

  const {
    unitsCollection,
    isFetchUnitsLoading,
    editedPolygonParameters,
    manualPointLayers
  } = useSelector<RootStateInterface, UnitsManagerStateInterface>(
    (state) => state.unitsManager
  );
  const { layers } = useSelector<
    RootStateInterface,
    LayersManagerStateInterface
  >((state) => state.layersManager);

  const { alias: providerModalAlias } = useSelector<
    RootStateInterface,
    ProviderManagerModalInterface
  >((state) => state.modal.modalProps);

  const isProcessing = useMemo(
    () =>
      isProjectLoading ||
      isLayerCreateLoading ||
      isMarkersLoading ||
      isFetchUnitsLoading ||
      isMarkerCreateLoading,
    [
      isLayerCreateLoading,
      isMarkersLoading,
      isProjectLoading,
      isMarkerCreateLoading,
      isFetchUnitsLoading
    ]
  );
  const selectedLayers = useMemo(() => {
    // return layers;
    //`layer.alias !== providerModalAlias` is added for rerendering when tiles are reordered
    return layers.filter(
      (layer) => layer.isActive && layer.alias !== providerModalAlias
    );
  }, [layers, providerModalAlias]);
  const selectedAries = useMemo(
    () => aries.filter((aria) => aria.isActive),
    [aries]
  );
  const selectedMarkers = useMemo(() => {
    return [];
  }, []);

  const availablemanualPointLayers = useMemo(
    () =>
      manualPointLayers.filter((layer) =>
        unitsCollection[LayerAlias.MANUAL][UnitTypes.POINT].units.find(
          (item) => item.additional.object_type === layer.value
        )
      ),
    [manualPointLayers, unitsCollection]
  );
  const selectedmanualPointLayers = useMemo(
    () => manualPointLayers.filter((layer) => layer.isActive),
    [manualPointLayers]
  );
  const selectedPointMarkers = useMemo(
    () =>
      unitsCollection[LayerAlias.MANUAL][UnitTypes.POINT].units.filter(
        (marker) =>
          selectedmanualPointLayers.find(
            (selectedLayer) =>
              selectedLayer.value === marker.additional.object_type
          )
      ),
    [selectedmanualPointLayers, unitsCollection]
  );
  // const activeMarker = useMemo(
  //   () => selectedMarkers.find((marker) => marker.id === markerDetails?.id),
  //   [markerDetails?.id, selectedMarkers]
  // );
  // const thermalMarkers = useMemo(() => {
  //   return selectedMarkers.filter((item) => item.layer === LayerAlias.THERMAL);
  // }, [selectedMarkers]);

  const defaultActiveLayer = useMemo(() => {
    return layers?.find((item) => item.providers?.length > 0);
  }, [layers]);

  const mapCenter = useMemo(() => {
    const center = defaultActiveLayer?.providers?.[0]?.data?.center;
    return center ? { lng: center[0], lat: center[1] } : null;
  }, [defaultActiveLayer]);

  const mapZoom = useMemo(() => {
    const zoom = defaultActiveLayer?.providers?.[0]?.data?.center?.[2];
    const maxNativeZoom = defaultActiveLayer?.providers?.[0]?.data?.maxzoom;

    return {
      zoom: zoom > 0 ? zoom : 9,
      maxNativeZoom: maxNativeZoom > 0 ? maxNativeZoom : 26
    };
  }, [defaultActiveLayer]);

  useEffect(() => {
    dispatch(features.map.actions.fetchProjectRequest({ params: { id } }));
    dispatch(
      features.unitsManager.actions.fetchUnitsRequest({
        params: {
          projectId: id,
          limit: UNITS_LIMIT,
          skip: UNITS_SKIP_COUNT,
          type: UnitTypes.POLYGON,
          alias: LayerAlias.MANUAL
        }
      })
    );

    dispatch(
      features.unitsManager.actions.fetchUnitsRequest({
        params: {
          projectId: id,
          limit: 9999999999,
          skip: UNITS_SKIP_COUNT,
          type: UnitTypes.POINT,
          alias: LayerAlias.MANUAL
        }
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (isProcessing)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Processing",
            loading: true,
            isDisableClose: true
          }
        })
      );
    else dispatch(features.modal.actions.hideModal());
  }, [dispatch, isProcessing]);

  useEffect(() => {
    return () => {
      dispatch(features.modal.actions.hideModal());
      dispatch(features.map.actions.clearState());
      dispatch(features.layersManager.actions.clearState());
    };
  }, [dispatch, location.key]);

  const setView = (...args) => {
    if (mapRef.current) {
      mapRef.current.setView(...args);
    }
  };

  const createPerimeter = (
    point1: LatLngTuple,
    point2: LatLngTuple,
    point3: LatLngTuple,
    point4: LatLngTuple
  ) => {
    const bounds: LatLngTuple[] = [point1, point2, point3, point4];
    setPerimeterBounds(bounds);

    const avgLat =
      bounds.reduce((sum, item) => sum + item[0], 0) / bounds.length;
    const avgLng =
      bounds.reduce((sum, item) => sum + item[1], 0) / bounds.length;

    mapRef.current.setView({ lat: avgLat, lng: avgLng }, 18);
  };

  const removePerimeter = () => {
    setPerimeterBounds(null);
  };

  useEffect(() => {
    (window as any).createPerimeter = createPerimeter;
    (window as any).removePerimeter = removePerimeter;
  }, []);

  const magneticLayer = useMemo(() => {
    return selectedLayers.find((item) => item.alias === LayerAlias.MAGNETIC);
  }, [selectedLayers]);

  const createPolygonValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required")
  });

  const onUnitDelete = useCallback(
    (unit) => () => {
      dispatch(
        features.modal.actions.showModal({
          modalType: "DELETE",
          modalProps: {
            title: "Delete area?",
            subtitle: `Are you sure you want to delete area "${unit.name}"? This action cannot be undone.`,
            onAcceptClick: () => {
              dispatch(
                features.unitsManager.actions.deleteUnitRequest({
                  params: {
                    projectId: id,
                    unitId: unit.id,
                    type: UnitTypes.POLYGON,
                    alias: LayerAlias.MANUAL
                  },
                  onSuccess: () => {
                    dispatch(
                      features.unitsManager.actions.fetchUnitsRequest({
                        params: {
                          projectId: id,
                          limit: UNITS_LIMIT,
                          skip: 0,
                          type: UnitTypes.POLYGON,
                          alias: LayerAlias.MANUAL
                        }
                      })
                    );
                    dispatch(features.modal.actions.hideModal());
                    dispatch(
                      features.unitsManager.actions.clearEditPolygonParameters()
                    );
                  }
                })
              );
            }
          }
        })
      );
    },
    [dispatch, id]
  );

  const MapEventHandler = () => {
    function isPointInBounds(point, bounds) {
      const { lat, lng } = point;
      const [minLng, minLat, maxLng, maxLat] = bounds;
      return lng >= minLng && lng <= maxLng && lat >= minLat && lat <= maxLat;
    }
    // const map = useMapEvents({
    useMapEvents({
      contextmenu(event) {
        const clickedPoint = event.latlng;
        const containerPoint = event.containerPoint;
        selectedLayers.map((layer) => layer.providers);

        const options = selectedLayers.reduce((acc, layer) => {
          if (Array.isArray(layer.providers)) {
            layer.providers.forEach((provider) => {
              if (
                provider.data.bounds &&
                isPointInBounds(clickedPoint, provider.data.bounds)
              ) {
                const option = {
                  label: provider.data.name,
                  value: provider.id,
                  icon: TrashIcon,
                  alias: layer.alias
                };

                acc.push(option);
              }
            });
          }
          return acc;
        }, []);

        setContextMenu({
          position: { x: containerPoint.x, y: containerPoint.y },
          visible: true,
          options
        });
      },
      click() {
        setContextMenu({
          position: null,
          options: null,
          visible: false
        });
      }
    });
    return null;
  };

  const ContextMenu = ({ position, visible, options, onClick }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isOverflowHidden, setIsOverflowHidden] = useState(false);

    useEffect(() => {
      if (visible) setIsOverflowHidden(true);
      setTimeout(() => {
        setIsOverflowHidden(false);
        setIsVisible(visible);
      }, 401);
    }, [visible]);

    return (
      <div
        className={styles["context-menu"]}
        style={{
          top: position?.y,
          left: position?.x,
          overflow: isOverflowHidden ? "hidden" : "unset"
        }}
      >
        {options?.length > 0 && (
          <DropdownMenu
            options={options}
            isActive={isVisible}
            onClick={onClick}
          />
        )}
      </div>
    );
  };

  const onClickContextItem = useCallback(
    (item) => {
      if (item) {
        setContextMenu({ position: null, options: null, visible: false });
        // http://10.10.90.250:8000/map/project/296/task/efc9e804-a6cf-4a1e-84a1-ba957fa04f81/
        // http://10.10.90.250:8000/map/project/264/task/f9901e46-b076-453c-bde4-7a4ff89399e9/
        const { alias, value: providerId } = item;

        dispatch(
          features.modal.actions.showModal({
            modalType: "DELETE",
            modalProps: {
              title: `Delete ${item.label}?`,
              subtitle: `Are you sure you want to delete ${item.label}? This action cannot be undone.`,
              onAcceptClick: () => {
                dispatch(
                  features.layersManager.actions.deleteProviderRequest({
                    params: { id, alias, providerId }
                  })
                );
                dispatch(features.modal.actions.hideModal());
              }
            }
          })
        );
      }
    },
    [dispatch, id]
  );
  // TODO
  // useEffect(() => {
  //   const audio = new Audio(NotificationSound);
  //   audio.play().catch((error) => {
  //     console.error("Failed to play sound:", error);
  //   });
  // }, []);

  const openPolygonDrawMode = useCallback(
    (areaData) => {
      setMapMode(MapModesEnum.POLYGON_CREATION);

      const recevedAreaData = JSON.parse(JSON.stringify(areaData));
      recevedAreaData.geo_data.coordinates.pop(); // here we remove the closing coordinate to be able to add new points to the existing polygon

      const polygonWithoutClosedPoint = {
        ...recevedAreaData,
        geo_data: {
          ...recevedAreaData.geo_data,
          coordinates: [...recevedAreaData.geo_data.coordinates]
        }
      };
      dispatch(
        features.unitsManager.actions.setEditPolygonParameters({
          ...polygonWithoutClosedPoint
        })
      );
    },
    [dispatch]
  );

  const handlePolygonEditOpen = useCallback(
    (areaData) => {
      const isEditMode = !!areaData.id;
      const polygonCoordinates = ensureClosedPolygon(
        areaData.geo_data.coordinates
      );

      const polygon = turf.polygon([polygonCoordinates]);

      const areaInSquareMeters = turf.area(polygon);
      const areaInHectares = areaInSquareMeters / 10000;

      dispatch(
        features.modal.actions.showModal({
          modalType: "FORM",
          modalProps: {
            title: "Customize area",
            onSubmit: (values) => {
              const fields = {
                ...values,
                geo_data: {
                  ...values.geo_data,
                  coordinates: polygonCoordinates
                },
                additional: {
                  ...values.additional,
                  area: areaInSquareMeters,
                  stroke_transparency: convertPercentagesToNumber(
                    values.additional.stroke_transparency
                  ),
                  fill_transparency: convertPercentagesToNumber(
                    values.additional.fill_transparency
                  )
                }
              };
              if (isEditMode) {
                dispatch(
                  features.unitsManager.actions.updateUnitRequest({
                    params: {
                      projectId: id,
                      unitId: areaData.id,
                      alias: LayerAlias.MANUAL,
                      type: UnitTypes.POLYGON
                    },
                    fields,
                    onSuccess: () => {
                      dispatch(features.modal.actions.hideModal());
                    }
                  })
                );
              } else {
                dispatch(
                  features.unitsManager.actions.createUnitRequest({
                    params: {
                      projectId: id,
                      alias: LayerAlias.MANUAL,
                      type: UnitTypes.POLYGON
                    },
                    fields,
                    onSuccess: () => {
                      dispatch(
                        features.unitsManager.actions.clearEditPolygonParameters()
                      );
                      dispatch(features.modal.actions.hideModal());
                    }
                  })
                );
              }
              dispatch(
                features.unitsManager.actions.clearEditPolygonParameters()
              );
            },
            acceptButtonLabel: "Save",
            containerClassName: styles["creation-area-modal-container"],
            validationSchema: createPolygonValidationSchema,
            initialValues: areaData,
            formContentWithContext: ({ setFieldValue, values }) => (
              <div className={styles["area-modal-content-wrapper"]}>
                <MapCreatePolygonModalForm
                  setValue={setFieldValue}
                  values={values}
                  area={areaInHectares}
                />
                {isEditMode && (
                  <CommonButton
                    icon={DeleteIcon}
                    onClick={onUnitDelete(areaData)}
                    className={styles["delete-unit-button"]}
                  >
                    Delete area
                  </CommonButton>
                )}
              </div>
            )
          }
        })
      );
    },
    [id, dispatch, createPolygonValidationSchema, onUnitDelete]
  );

  const handleAcceptDrawPolygonClick = useCallback(() => {
    handlePolygonEditOpen(editedPolygonParameters);
  }, [editedPolygonParameters, handlePolygonEditOpen]);

  const handleDeclineDrawPolygonClick = useCallback(() => {
    dispatch(features.unitsManager.actions.clearEditPolygonParameters());
    setMapMode(MapModesEnum.DEFAULT);
  }, [dispatch]);

  const handlePolygonRightClick = useCallback(
    (areaData) => {
      openPolygonDrawMode(areaData);
    },
    [openPolygonDrawMode]
  );

  const handleEditUnitsListItem = useCallback(
    (data) => {
      openPolygonDrawMode(data);
    },
    [openPolygonDrawMode]
  );

  const availablePolygons = useMemo(
    () =>
      unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON].units
        ?.filter((el) => el.isVisible && el.id !== editedPolygonParameters.id)
        .map((el, index) => {
          // You need to use a long key that will include all the polygon parameters that can change.
          const key = `${index}-${el.additional.stroke_color}-${el.additional.fill_color}-${el.additional.stroke_transparency}-${el.additional.fill_transparency}`;

          return (
            <Polygon
              positions={el.geo_data.coordinates}
              key={key}
              color={el.additional.stroke_color}
              fillColor={el.additional.fill_color}
              opacity={convertPercentagesToNumber(
                el.additional.stroke_transparency
              )}
              fillOpacity={convertPercentagesToNumber(
                el.additional.fill_transparency
              )}
              eventHandlers={{
                contextmenu: () => handlePolygonRightClick(el)
              }}
            />
          );
        }),
    [editedPolygonParameters.id, handlePolygonRightClick, unitsCollection]
  );

  return (
    <div
      className={cn(styles["map-page"], {
        [styles["map-page__draw-mode"]]:
          mapMode === MapModesEnum.POLYGON_CREATION
      })}
    >
      {title && (
        <MapProjectName name={title} className={styles["project-title"]} />
      )}
      <MapLayers
        layers={layers}
        aries={aries}
        setView={setView}
        points={availablemanualPointLayers}
        unitsCollection={unitsCollection}
        editUnitAction={handleEditUnitsListItem}
      />
      <ContextMenu
        position={contextMenu.position}
        visible={contextMenu.visible}
        options={contextMenu.options}
        onClick={onClickContextItem}
      />
      {title && (
        <MapContainer
          zoom={mapZoom.zoom}
          zoomControl={false}
          center={mapCenter ? mapCenter : KYIV_COORDINATES}
          className={styles["map"]}
          pmIgnore={false}
          maxZoom={28}
          ref={mapRef}
          preferCanvas={true}
        >
          <MapEventHandler />

          <TileLayer
            url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxNativeZoom={20}
            maxZoom={30}
          />
          {selectedLayers
            .filter((layer) => layer.alias !== LayerAlias.MAGNETIC)
            .map((item) =>
              item?.providers?.map((provider) => (
                <TileLayer
                  accessToken={getToken()}
                  url={`${GEO_VISION_API_URL}/projects/${id}/layers/${
                    item.alias === LayerAlias.SENSOR_FUSION
                      ? LayerAlias.RGB
                      : item.alias
                  }/providers/${provider.id}/tiles/{z}/{x}/{y}${
                    item.params ?? ""
                  }`}
                  bounds={
                    provider.data?.bounds
                      ? new L.LatLngBounds([
                          [
                            provider?.data?.bounds[1],
                            provider?.data?.bounds[0]
                          ],
                          [provider?.data?.bounds[3], provider?.data?.bounds[2]]
                        ])
                      : null
                  }
                  maxNativeZoom={mapZoom.maxNativeZoom}
                  maxZoom={30}
                  opacity={(item.opacity ?? 100) / 100}
                  detectRetina={true}
                  key={`tile-${item.alias}-${provider.id}`}
                  id={provider.data.name}
                />
              ))
            )}

          {selectedLayers.map((layers) => (
            <MapMarkers
              markers={unitsCollection[layers.alias][UnitTypes.POINT].units}
              activeMarkerId={markerDetails?.id}
              opacityByLayerList={selectedLayers.filter(
                (layer) => layer.alias === LayerAlias.THERMAL
              )}
              alias={layers.alias}
              key={`${layers.alias}-collection-points`}
            />
          ))}

          <MapMarkers
            markers={selectedPointMarkers}
            activeMarkerId={markerDetails?.id}
            opacityByLayerList={selectedLayers.filter(
              (layer) => layer.alias === LayerAlias.THERMAL
            )}
            alias={LayerAlias.MANUAL}
          />

          {magneticLayer?.providers?.length > 0 && (
            <TileLayer
              url={`${STORAGE_URL}/mapbuilder/projects/${id}/mag/tiles/{z}/{x}/{y}.png`}
              maxNativeZoom={26}
              maxZoom={30}
              opacity={(magneticLayer?.opacity ?? 100) / 100}
            />
          )}
          {selectedAries?.length > 0 && selectedMarkers.length > 0 && (
            <MapPolygons aries={selectedAries} markers={selectedMarkers} />
          )}
          {/* {thermalMarkers.length > 0 && (
            <MapPolygons
              aries={[
                {
                  name: "Thermal Area",
                  color: selectedLayers.find(
                    (layer) => layer.alias === LayerAlias.THERMAL
                  ).color,
                  isActive: true,
                  alias: LayerAlias.THERMAL,
                  radius: 0.2
                }
              ]}
              markers={thermalMarkers}
            />
          )} */}
          <ZoomBtns
            zoomIn={() => mapRef.current.zoomIn()}
            zoomOut={() => mapRef.current.zoomOut()}
          />
          {mapMode === MapModesEnum.DEFAULT && <MapSearchPoint />}
          {mapMode === MapModesEnum.POLYGON_CREATION && <MapPolygonBuilder />}
          {mapMode === MapModesEnum.POLYGON_CREATION &&
          editedPolygonParameters.geo_data.coordinates.length > 2 ? (
            <MapConfirmControls
              onAccept={handleAcceptDrawPolygonClick}
              onDecline={handleDeclineDrawPolygonClick}
            />
          ) : (
            <MapToolbar onChangeMode={setMapMode} />
          )}

          {perimeterBounds && (
            <Polygon positions={perimeterBounds} color="blue" />
          )}

          {unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON]?.units
            ?.length > 0 && availablePolygons}
        </MapContainer>
      )}
      <MapMarkerDetails
        markerDetails={markerDetails}
        isMarkerDetailsLoading={isMarkerDetailsLoading}
        isMarkerDeleteLoading={isMarkerDeleteLoading}
        projectId={id}
        layers={layers}
      />
    </div>
  );
};

export default MapPage;
