import { put, takeLatest } from "redux-saga/effects";

import { requestMiddleware } from "helpers/api";
import { getWikiDetails } from "services/api/map";
import { getProject } from "services/api/project";
import { actions } from "./ducks";
import features from "features";

function* fetchProject({ payload }) {
  const request = getProject;

  const { fetchProjectSuccess: setSuccess, fetchProjectError: setError } =
    actions;

  function* onSuccess(res) {
    if (res.layers?.length)
      yield put(
        features.layersManager.actions.setLayers({
          params: payload.params,
          layers: res.layers
        })
      );
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess
  });
}

function* fetchPointWikiDetails({ payload }) {
  const request = getWikiDetails;

  const {
    fetchPointWikiDetailsSuccess: setSuccess,
    fetchPointWikiDetailsError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* initPointData({ payload }) {
  const { fetchPointWikiDetailsRequest } = actions;
  const id = payload.additional.mine_id;

  if (id) yield put(fetchPointWikiDetailsRequest({ params: { id } }));
}

export default function* watchSaga() {
  yield takeLatest(actions.fetchProjectRequest, fetchProject);
  yield takeLatest(actions.fetchPointWikiDetailsRequest, fetchPointWikiDetails);
  yield takeLatest(actions.setPointDetails, initPointData);
}
