import L from "leaflet";
import { useMemo } from "react";
import { renderToString } from "react-dom/server";
import { Marker, Polygon, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { convertPercentagesToNumber } from "helpers/convertPercentagesToNumber";
import { RootStateInterface } from "reducer";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";

import styles from "./map-polygon-builder.module.scss";

const MapPolygonBuilder = () => {
  const dispatch = useDispatch();

  const { editedPolygonParameters } = useSelector<
    RootStateInterface,
    UnitsManagerStateInterface
  >((state) => state.unitsManager);

  const MapClickHandler = () => {
    useMapEvents({
      click: (event) => {
        const { lat, lng } = event.latlng;
        dispatch(
          features.unitsManager.actions.setEditPolygonParameters({
            ...editedPolygonParameters,
            geo_data: {
              ...editedPolygonParameters.geo_data,
              coordinates: [
                ...editedPolygonParameters.geo_data.coordinates,
                [lat, lng]
              ]
            }
          })
        );
      }
    });

    return null;
  };

  const MarkerIcon = L.divIcon({
    html: renderToString(<div className={styles["marker"]} />),
    iconSize: [16, 16],
    className: "marker-reset"
  });

  const updateMarkerPlacement = (index, newCoordinate) => {
    const updatedCoordinates = editedPolygonParameters.geo_data.coordinates.map(
      (coord, i) => (i === index ? newCoordinate : coord)
    );

    dispatch(
      features.unitsManager.actions.setEditPolygonParameters({
        ...editedPolygonParameters,
        geo_data: {
          ...editedPolygonParameters.geo_data,
          coordinates: updatedCoordinates
        }
      })
    );
  };

  // You need to use a long key that will include all the polygon parameters that can change.
  const generatedPolygonKey = useMemo(
    () =>
      `${editedPolygonParameters.additional.stroke_color}-${editedPolygonParameters.additional.fill_color}-${editedPolygonParameters.additional.stroke_transparency}-${editedPolygonParameters.additional.fill_transparency}`,
    [
      editedPolygonParameters.additional.fill_color,
      editedPolygonParameters.additional.fill_transparency,
      editedPolygonParameters.additional.stroke_color,
      editedPolygonParameters.additional.stroke_transparency
    ]
  );

  return (
    <div className={styles["polygon-builder"]}>
      <MapClickHandler />

      {editedPolygonParameters.geo_data.coordinates.length > 1 && (
        <Polygon
          key={generatedPolygonKey}
          positions={editedPolygonParameters.geo_data.coordinates}
          color={editedPolygonParameters.additional.stroke_color}
          fillColor={editedPolygonParameters.additional.fill_color}
          opacity={convertPercentagesToNumber(
            editedPolygonParameters.additional.stroke_transparency
          )}
          fillOpacity={convertPercentagesToNumber(
            editedPolygonParameters.additional.fill_transparency
          )}
        />
      )}

      {editedPolygonParameters.geo_data.coordinates.length > 0 &&
        editedPolygonParameters.geo_data.coordinates.map((point, index) => (
          <Marker
            key={index}
            position={point}
            icon={MarkerIcon}
            draggable={true}
            eventHandlers={{
              dragend: (event) => {
                const { lat, lng } = event.target.getLatLng();
                updateMarkerPlacement(index, [lat, lng]);
              }
            }}
          ></Marker>
        ))}
    </div>
  );
};

export default MapPolygonBuilder;
