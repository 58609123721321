import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PolygonColors } from "constant";
import features from "features";
import { RootStateInterface } from "reducer";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";

import FormField from "components/inputs/FormField/FormField";
import ColorConfigurator from "./ColorConfigurator";

import { ReactComponent as AreaIcon } from "assets/icons/square-area-black.svg";

import styles from "./map-create-polygon-modal-form.module.scss";

interface MapCreatePolygonModalFormInterface {
  setValue: any;
  values: any;
  area: number;
}

enum ColorsSubjects {
  STROKE_COLOR = "stroke_color",
  FILL_COLOR = "fill_color"
}

enum OpacitySubjects {
  STROKE_TRANSPARENCY = "stroke_transparency",
  FILL_TRANSPARENCY = "fill_transparency"
}

interface ColorsInterface {
  colorsSubject: ColorsSubjects;
  opacitySubject: OpacitySubjects;
}

interface AreaColorInterface {
  colorName: string;
  color: string;
  opacity?: number;
  isActive: boolean;
  action: () => void;
}

const MapCreatePolygonModalForm = ({
  setValue,
  values,
  area
}: MapCreatePolygonModalFormInterface) => {
  const dispatch = useDispatch();
  const { editedPolygonParameters } = useSelector<
    RootStateInterface,
    UnitsManagerStateInterface
  >((state) => state.unitsManager);

  const colorsAreaList = useCallback(
    ({
      colorsSubject,
      opacitySubject
    }: ColorsInterface): Array<AreaColorInterface> => {
      const onChangeColor = (subject: string, color: PolygonColors) => {
        setValue(`additional.${subject}`, color);
        dispatch(
          features.unitsManager.actions.setEditPolygonParameters({
            ...editedPolygonParameters,
            additional: {
              ...editedPolygonParameters.additional,
              [subject]: color
            }
          })
        );
      };

      return [
        {
          colorName: "red",
          color: PolygonColors.RED,
          opacity: values.additional[`${opacitySubject}`],
          isActive: values.additional[`${colorsSubject}`] === PolygonColors.RED,
          action: () => onChangeColor(colorsSubject, PolygonColors.RED)
        },
        {
          colorName: "orange",
          color: PolygonColors.ORANGE,
          opacity: values.additional[`${opacitySubject}`],
          isActive:
            values.additional[`${colorsSubject}`] === PolygonColors.ORANGE,
          action: () => onChangeColor(colorsSubject, PolygonColors.ORANGE)
        },
        {
          colorName: "green",
          color: PolygonColors.GREEN,
          opacity: values.additional[`${opacitySubject}`],
          isActive:
            values.additional[`${colorsSubject}`] === PolygonColors.GREEN,
          action: () => onChangeColor(colorsSubject, PolygonColors.GREEN)
        },
        {
          colorName: "yellow",
          color: PolygonColors.YELLOW,
          opacity: values.additional[`${opacitySubject}`],
          isActive:
            values.additional[`${colorsSubject}`] === PolygonColors.YELLOW,
          action: () => onChangeColor(colorsSubject, PolygonColors.YELLOW)
        },
        {
          colorName: "blue",
          color: PolygonColors.BLUE,
          opacity: values.additional[`${opacitySubject}`],
          isActive:
            values.additional[`${colorsSubject}`] === PolygonColors.BLUE,
          action: () => onChangeColor(colorsSubject, PolygonColors.BLUE)
        }
      ];
    },
    [dispatch, editedPolygonParameters, setValue, values.additional]
  );

  const handlePolygonNameChange = useCallback(
    (e) => {
      setValue("name", e.target.value);
      dispatch(
        features.unitsManager.actions.setEditPolygonParameters({
          ...editedPolygonParameters,
          name: e.target.value
        })
      );
    },
    [dispatch, editedPolygonParameters, setValue]
  );

  return (
    <div className={styles["modal__form"]}>
      <div className={styles["main-area-data-container"]}>
        <div className={styles["area-container"]}>
          <label className={styles["area-label"]}>Area</label>
          <div className={styles["area__count-container"]}>
            <AreaIcon />
            <span className={styles["area-count"]}>{area.toFixed(2)} га</span>
          </div>
        </div>
        <FormField
          name={"name"}
          value={values["name"]}
          placeholder="Name point"
          onChange={handlePolygonNameChange}
        />
      </div>
      <div className={styles["color-configuration-block"]}>
        <ColorConfigurator
          colorSubjectName="Stroke"
          colorsList={colorsAreaList({
            colorsSubject: ColorsSubjects.STROKE_COLOR,
            opacitySubject: OpacitySubjects.STROKE_TRANSPARENCY
          })}
          formValues={values}
          setValue={setValue}
          opacitySubject={OpacitySubjects.STROKE_TRANSPARENCY}
        />
        <ColorConfigurator
          colorSubjectName="Fill"
          colorsList={colorsAreaList({
            colorsSubject: ColorsSubjects.FILL_COLOR,
            opacitySubject: OpacitySubjects.FILL_TRANSPARENCY
          })}
          formValues={values}
          setValue={setValue}
          opacitySubject={OpacitySubjects.FILL_TRANSPARENCY}
        />
      </div>
    </div>
  );
};

export default MapCreatePolygonModalForm;
