import { createSlice } from "@reduxjs/toolkit";
// import { UserRoles } from "constant";

interface jwtData {
  org_id: number;
  dep_id: number;
  role: string;
  username: string;
  policies: Array<string>;
  name: string;
  surname: string;
  dep_name: string;
}

export interface AuthStateInterface {
  isLoading: boolean;
  jwtData: jwtData;
}

const initialState: AuthStateInterface = {
  isLoading: false,
  jwtData: null
};

export const mainSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initTokenRequest: (state) => {
      state.isLoading = true;
    },
    initTokenSuccess: (state) => {
      state.isLoading = false;
    },
    initTokenError: (state) => {
      state.isLoading = false;
    },

    setTokenData: (state, { payload }) => {
      state.jwtData = payload;
    },

    signOutRequest: () => {},
    signOutSuccess: () => {},
    signOutError: () => {}
  }
});

export const { actions, reducer } = mainSlice;
