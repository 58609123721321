import classNames from "classnames";

import styles from "./table.module.scss";

export interface HeadItemInterface {
  title: string;
  alias?: string;
  mutator?: "increment" | ((val: any) => string | JSX.Element[]);
  tdClass?: string;
  thClass?: string;
  element?: (val: any, i: number) => JSX.Element;
}
interface BodyDataInterface {}

interface TableInterface {
  className?: string;
  headers: HeadItemInterface[];
  body: BodyDataInterface[];
  maxHeight?: number;
}

const ValueComponent = ({ element, value, mutator, i }) => {
  if (mutator === "increment") return i + 1;

  if (!!!value) return "-";

  let operatedValue = value;

  if (mutator) operatedValue = mutator(value);

  if (element) return element(operatedValue, i);

  return operatedValue;
};

const Table = ({
  className,
  headers,
  body: bodyData,
  maxHeight
}: TableInterface) => {
  return (
    <div
      className={classNames(styles["table-container"], className)}
      style={{ maxHeight }}
    >
      <table>
        <thead>
          <tr>
            {headers.map((head, i) => (
              <th className={head.thClass} key={`head-${i}`}>
                {head.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bodyData.map((item, itemI) => (
            <tr key={`row-${itemI}`}>
              {headers.map((head, i) => {
                const itemData = item[head.alias];

                return (
                  <td key={`row-${itemI},col-${i}`} className={head.tdClass}>
                    <ValueComponent
                      element={head.element}
                      value={itemData}
                      i={itemI}
                      mutator={head.mutator}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
