import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_LAYERS_LIST, InferenceStatus, LayerInterface } from "constant";

interface LayerProviderInterface {
  id?: any;
  data: {
    name?: string;
    bounds?: number[];
    center?: number[];
    maxzoom?: number;
    rescale_min?: number;
    rescale_max?: number;
    task?: string;
    project?: string;
  };
}
export interface MapLayerInterface extends LayerInterface {
  data?: any;
  params?: string;
  icon?: string;
  isActive?: boolean;
  providers?: LayerProviderInterface[];
  opacity?: number;
  inference?: any;
}

export interface LayersManagerStateInterface {
  isCreateProviderLoading: boolean;
  isReorderProviderLoading: boolean;
  providers: any;
  layers: MapLayerInterface[];
}

const initialState: LayersManagerStateInterface = {
  isCreateProviderLoading: false,
  isReorderProviderLoading: false,
  providers: [],
  layers: DEFAULT_LAYERS_LIST
};

const combineLayersList = (oldLayers: MapLayerInterface[], newLayers: any) => {
  let combinedArray = [...oldLayers, ...newLayers].reduce((acc, obj) => {
    acc[obj.alias] = {
      ...obj
    };
    return acc;
  }, {});
  combinedArray = Object.values(combinedArray);
  return combinedArray;
};

const combineProviderList = (oldProviders: any, newProviders: any) => {
  if (!newProviders) return [];

  const oldProviderObjectById = oldProviders.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const combinedArray = newProviders.map((item) => ({
    ...item,
    ...oldProviderObjectById[item.id],
    index: item.index
  }));

  return combinedArray;
};

export const mainSlice = createSlice({
  name: "layersManager",
  initialState,
  reducers: {
    setLayers: (state, { payload }) => {
      const combinedLayers = combineLayersList(state.layers, payload.layers);
      state.layers = combinedLayers;
    },
    startSensorFusingInferenceRequest: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { isInferenceLoading: true }
            }
          : layer
      );
    },
    startSensorFusingInferenceSuccess: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },
    startSensorFusingInferenceError: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },

    cancelSensorFusingInferenceRequest: (state, { payload }) => {
      const { alias } = payload.params;
      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: true }
            }
          : layer
      );
    },
    cancelSensorFusingInferenceSuccess: (state, { payload }) => {
      const { alias } = payload.params;
      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: {
                ...layer.inference,
                isInferenceLoading: false,
                status: InferenceStatus.STOPPING
              }
            }
          : layer
      );
    },
    cancelSensorFusingInferenceError: (state, { payload }) => {
      const { alias } = payload.params;

      state.layers = state.layers.map((layer) =>
        layer.alias === alias
          ? {
              ...layer,
              inference: { ...layer.inference, isInferenceLoading: false }
            }
          : layer
      );
    },

    setLayerParams: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, params: payload.params }
          : item;
      });
    },
    setLayerOpacity: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, opacity: payload.opacity }
          : item;
      });
    },

    setLayerIsActive: (state, { payload }) => {
      state.layers = state.layers.map((item) =>
        item.alias === payload.params.alias
          ? { ...item, isActive: payload.fields?.isActive }
          : item
      );
    },

    setProviders: (state, { payload }) => {
      state.providers = payload.providers ?? [];
    },
    createProviderRequest: (state) => {
      state.isCreateProviderLoading = true;
    },
    setUploadInferenceFileCancelEvent: (state, { payload }) => {
      const { cancelExecutor, params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              inference: {
                ...provider.inference,
                status: InferenceStatus.UPLOADING,
                fileUploadData: {
                  ...provider.inference?.fileUploadData,
                  cancelExecutor
                }
              }
            }
          : provider;
      });
    },
    setUploadInferenceFileProgress: (state, { payload }) => {
      const { progress, params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              inference: {
                ...provider.inference,
                fileUploadData: {
                  ...provider.inference.fileUploadData,
                  progress
                }
              }
            }
          : provider;
      });
    },
    cancelUploadingInferenceFile: (state, { payload }) => {
      const { providerId } = payload.params;

      state.providers = state.providers.map((provider) => {
        if (provider.id === providerId) {
          provider.inference.fileUploadData.cancelExecutor();

          return {
            ...provider,
            inference: undefined
          };
        }
        return provider;
      });
    },

    createProviderSuccess: (state, { payload }) => {
      state.providers = combineProviderList(state.providers, payload.providers);

      state.isCreateProviderLoading = false;
    },
    createProviderError: (state) => {
      state.isCreateProviderLoading = false;
    },

    deleteProviderRequest: (state, { payload }) => {
      const providerId = payload.params.providerId;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isDeleteProviderLoading: true
            }
          : provider;
      });
    },
    deleteProviderSuccess: (state, { payload }) => {
      state.providers = combineProviderList(state.providers, payload.providers);
    },
    deleteProviderError: (state, { payload }) => {
      const providerId = payload.params.providerId;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isDeleteProviderLoading: false
            }
          : provider;
      });
    },

    reorderProviderRequest: (state, { payload }) => {
      const fromIndex = state.providers.find((item) => {
        return item.id.toString() === payload.params.providerId;
      }).index;
      const toIndex = payload.fields.index;
      const newArray = [...state.providers];
      const [movedElement] = newArray.splice(fromIndex, 1);
      newArray.splice(toIndex, 0, movedElement);

      state.providers = newArray;

      state.isReorderProviderLoading = true;
    },
    reorderProviderSuccess: (state, { payload }) => {
      state.isReorderProviderLoading = false;
      state.providers = combineProviderList(state.providers, payload.providers);
    },
    reorderProviderError: (state) => {
      state.isReorderProviderLoading = false;
    },

    fetchProviderInferenceStatusRequest: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              inference: {
                ...provider.inference,
                isInferenceStatusLoading: true
              }
            }
          : provider;
      });
    },
    fetchProviderInferenceStatusSuccess: (state, { payload }) => {
      const { params, data } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              inference: {
                ...provider.inference,
                ...data,
                isInferenceStatusLoading: false
              }
            }
          : provider;
      });
    },
    fetchProviderInferenceStatusError: (state, { payload }) => {
      const { params, data } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              inference: {
                ...provider.inference,
                ...data,
                isInferenceStatusLoading: false
              }
            }
          : provider;
      });
    },

    startProviderInferenceRequest: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: true
            }
          : provider;
      });

      // state.providers = state.providers.map((provider) => {
      //   return provider.id === providerId
      //     ? {
      //         ...provider,
      //         inference: {
      //           ...provider.inference,
      //           isInferenceStatusLoading: true
      //         }
      //       }
      //     : provider;
      // });
    },
    startProviderInferenceSuccess: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: false
            }
          : provider;
      });
    },
    startProviderInferenceError: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: false
            }
          : provider;
      });
    },

    cancelProviderInferenceRequest: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: true
            }
          : provider;
      });
    },
    cancelProviderInferenceSuccess: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: false
            }
          : provider;
      });
    },
    cancelProviderInferenceError: (state, { payload }) => {
      const { params } = payload;
      const { providerId } = params;

      state.providers = state.providers.map((provider) => {
        return provider.id === providerId
          ? {
              ...provider,
              isInferenceActionLoading: false
            }
          : provider;
      });
    },

    clearProvidersState: (state) => {
      state.providers = initialState.providers;
    },
    clearState: () => {
      return { ...initialState };
    }
  }
});

export const { actions, reducer } = mainSlice;
