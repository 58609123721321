import axios from "axios";

import { AUTH_URL } from "constant";
import { AuthProvider } from "services/ApiServiceProviders";

export const auth = ({ params }) => {
  const initTokenClient = axios.create({
    baseURL: AUTH_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${params.clientToken}`
    }
  });

  return initTokenClient
    .post(`/auth`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getTeams = ({ params, fields }) => {
  return AuthProvider.client.get(
    `/organizations/${params.org_id}/departments?parent_id=${params.dep_id}&limit=${params.limit}&skip=${params.skip}`,
    fields
  );
};

export const getTeam = ({ params }) => {
  return AuthProvider.client.get(
    `/organizations/${params.org_id}/departments/${params.dep_id}?&limit=999999`
  );
};

export const addTeam = ({ params, fields }) => {
  return AuthProvider.client.post(
    `/organizations/${params.org_id}/departments`,
    fields
  );
};

export const updateTeam = ({ params, fields }) => {
  return AuthProvider.client.put(
    `/organizations/${params.org_id}/departments/${params.id}`,
    fields
  );
};

export const removeTeam = ({ params }) => {
  return AuthProvider.client.delete(
    `/organizations/${params.org_id}/departments/${params.id}`
  );
};

export const addEmployee = ({ params, fields }) => {
  return AuthProvider.client.post(
    `/organizations/${params.org_id}/departments/${params.id}/users`,
    fields
  );
};
export const updateEmployee = ({ params, fields }) => {
  return AuthProvider.client.put(
    `/organizations/${params.org_id}/departments/${params.id}/users/${params.userId}`,
    fields
  );
};
export const removeEmployee = ({ params, fields }) => {
  return AuthProvider.client.delete(
    `/organizations/${params.org_id}/departments/${params.id}/users/${params.userId}`,
    fields
  );
};
export const addEmployeeCredentials = ({ params }) => {
  return AuthProvider.client.post(
    `/organizations/${params.org_id}/departments/${params.id}/users/${params.userId}/credentials`
  );
};
