import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootStateInterface } from "reducer";
import { IModalState } from "scenes/Modal/ducks";

import Header from "components/Header/Header";
import Toast from "components/Toast/Toast";
import IconButton from "components/buttons/IconButton/IconButton";
import ModalRoot from "components/modals/ModalRoot";

import SettingsIcon from "assets/icons/settings.svg";

import { AuthStateInterface } from "scenes/AuthPage/ducks";
import styles from "./layout.module.scss";

interface LayoutInterface {
  title?: string;
  isFullFill?: boolean;
  isWithoutHeader?: boolean;
  children: any;
  backPath?: string;
  isWithoutSettings?: boolean;
}

const Layout = ({
  title,
  backPath,
  isFullFill,
  isWithoutHeader,
  children,
  isWithoutSettings
}: LayoutInterface) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { isModalOpened } = useSelector<RootStateInterface, IModalState>(
    (state) => state.modal
  );
  const { jwtData } = useSelector<RootStateInterface, AuthStateInterface>(
    (state) => state.auth
  );

  const firstLetterUpperCase = (data?: string): string => {
    if (!data) return "";
    return data.charAt(0).toUpperCase() + data.slice(1);
  };

  const toSettings = useCallback(() => {
    navigate("/settings/department-and-staff");
  }, [navigate]);

  useEffect(() => {
    const body = document.body;

    if (isModalOpened) {
      body.style.overflowY = "hidden";
    } else {
      body.style.overflowY = "auto";
    }

    return () => {
      if (isModalOpened) {
        body.style.overflowY = "auto";
      }
    };
  }, [isModalOpened]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      {!isWithoutHeader && (
        <>
          <Header title={title} isFullFill={isFullFill} backPath={backPath} />
          {!isWithoutSettings && (
            <div className={styles["settings__wrapper"]}>
              <div className={styles["user"]}>
                {firstLetterUpperCase(jwtData?.role)} {jwtData?.name}{" "}
                {jwtData?.surname} {jwtData?.dep_name}
              </div>
              {jwtData?.role !== "user" && (
                <IconButton icon={SettingsIcon} onClick={toSettings} />
              )}
            </div>
          )}
        </>
      )}
      <main className={styles[isFullFill ? "main-full-fill" : "main"]}>
        {children}
      </main>
      <Toast />
      <ModalRoot />
    </>
  );
};

export default Layout;
