import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import * as Yup from "yup";

import { INFERENCE_STATUSES, InferenceStatus, LayerAlias } from "constant";
import features from "features";
import { useDispatch } from "react-redux";
import { RootStateInterface } from "reducer";
import { LayersManagerStateInterface } from "scenes/LayersManager/ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import IconButton from "components/buttons/IconButton/IconButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import FormField from "components/inputs/FormField/FormField";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import DownloadIcon from "assets/icons/download.svg";
import DragHandleDotsIcon from "assets/icons/drag-handle-dots.svg";
import TrashIcon from "assets/icons/trash.svg";

import InferenceAction from "components/InferenceAction/InferenceAction";
import InferenceStatusComponent from "components/InferenceStatusComponent/InferenceStatusComponent";
import styles from "./provider-manager-modal.module.scss";

export interface ProviderManagerModalInterface {
  title?: string;
  description?: string;
  isDisableClose?: boolean;
  acceptButtonLabel?: string;
  acceptButtonAction?: () => void;
  isWithProgress?: boolean;
  id: string;
  alias: LayerAlias;
  initProviders: any;
  onHide: (updatedProviders: any) => void;
}

const ProviderManagerModal = () => {
  const dispatch = useDispatch();

  const {
    title,
    description,
    isDisableClose,
    alias,
    id,
    initProviders,
    onHide
  } = useSelector<RootStateInterface, ProviderManagerModalInterface>(
    (state) => state.modal.modalProps
  );

  const { providers, isCreateProviderLoading } = useSelector<
    RootStateInterface,
    LayersManagerStateInterface
  >((state) => state.layersManager);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { destination, draggableId: providerId } = result;
    const index = destination.index;

    dispatch(
      features.layersManager.actions.reorderProviderRequest({
        params: { id, alias, providerId },
        fields: { index }
      })
    );
  };

  const initialValues = {
    link: ""
  };

  const validationSchema = Yup.object().shape({
    link: Yup.string().required("Odm link is required")
  });

  const onSubmit = useCallback(
    (e) => {
      dispatch(
        features.layersManager.actions.createProviderRequest({
          params: { id, alias },
          fields: { ...e }
        })
      );
      formik.resetForm();
    },
    [alias, dispatch, id]
  );

  const onDelete = useCallback(
    (providerId) => () => {
      dispatch(
        features.layersManager.actions.deleteProviderRequest({
          params: { id, alias, providerId }
        })
      );
    },
    [alias, dispatch, id]
  );

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  useEffect(() => {
    dispatch(
      features.layersManager.actions.setProviders({
        params: { alias, id },
        providers: initProviders
      })
    );
  }, [alias, dispatch, id, initProviders]);

  useEffect(() => {
    return () => {
      dispatch(features.layersManager.actions.clearProvidersState());
    };
  }, [dispatch]);

  const onClose = useCallback(() => {
    onHide(providers);
    dispatch(features.modal.actions.hideModal());
  }, [dispatch, onHide, providers]);

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      isDisableClose={isDisableClose}
      onHide={onClose}
    >
      <div className={styles["modal"]}>
        <div className={styles["modal__text-container"]}>
          <h2 className={styles["modal__title"]}>{title}</h2>
          <h4 className={styles["modal__description"]}>
            {description ?? "Manage files"}
          </h4>
          <div className={styles["modal__providers-table"]}>
            <div className={styles["providers-table__head"]}>
              <div>Name</div>
              <div>ODM link</div>
              <div>Action</div>
              <div>Inference action</div>
              <div>Inference status</div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {providers?.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style
                            }}
                            className={styles["providers-table__body"]}
                          >
                            <div className={styles["drag-dots"]}>
                              <img src={DragHandleDotsIcon} alt="drag-dots" />
                            </div>
                            <div className={styles["name"]}>
                              {item.data.name}
                            </div>
                            <div className={styles["link"]}>
                              {item.data.link}
                            </div>
                            <div>
                              <IconButton
                                onClick={onDelete(item.id)}
                                icon={TrashIcon}
                                disabled={item.isDeleteProviderLoading}
                                isLoading={item.isDeleteProviderLoading}
                              />
                            </div>
                            <InferenceAction
                              id={id}
                              providerId={item.id}
                              alias={alias}
                              status={
                                INFERENCE_STATUSES[item?.inference?.status]
                              }
                              isLoading={item.isInferenceActionLoading}
                              disabled={item.isDeleteProviderLoading}
                            />
                            <InferenceStatusComponent
                              status={
                                INFERENCE_STATUSES[item?.inference?.status]
                              }
                              completionPercentage={
                                item?.inference?.status ===
                                InferenceStatus.PROCESSING
                                  ? item?.inference?.data?.completion_percentage
                                  : undefined
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <FormikProvider value={formik}>
              <Form className={styles["form"]}>
                <FormField
                  className={styles["field"]}
                  placeholder="Add link"
                  name={"link"}
                />
                <CommonButton
                  type="submit"
                  icon={DownloadIcon}
                  isLoading={isCreateProviderLoading}
                  disabled={isCreateProviderLoading}
                >
                  Add
                </CommonButton>
              </Form>
            </FormikProvider>
          </div>
        </div>
        <div className={styles["modal__controls-wrapper"]}>
          <PrimaryButton type="button" onClick={onClose} textSize={14}>
            Done
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default ProviderManagerModal;
